import React, { Component, Fragment } from 'react';
import { Card, Col, Row, Popconfirm, Icon, Tag, notification, Button, Modal, Input, Select, Radio, Form, Spin } from 'antd';
// import AuctionEditForm from 'AuctionEditForm'
import { listProceedings } from 'queries'
import { deleteProceeding, createProceeding, updateProceeding } from 'mutations'
import { Query, Mutation } from 'react-apollo'
import gql from "graphql-tag";
import moment from 'moment'
import {
    Link
}
from 'react-router-dom';
const { TextArea } = Input
const Option = Select.Option
const FormItem = Form.Item

const Search = Input.Search;

class NewProc extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            _unmounted: false,
            modalVisible: false,
            newTitle: null,
            newDesc: null,
            newType1: null,
            newType2: null
        }
    }

    componentWillUnmount() {
        this._unmounted = true;
    }

    setModalVisible(modalVisible) {
        this.setState({ modalVisible });
    }

    createProceeding(CreateProceeding) {
        // const { newTitle, newDesc, newType1, newType2 } = this.state
        // let input = { title: newTitle, step: 1, type1: newType1, type2: newType2 }
        // if (newDesc) input['desc'] = newDesc
        const _this2 = this;
        this.setState({ saving: true });

        this.props.form.validateFields((err, values) => {

            if (!err) {
                CreateProceeding({
                        variables: {
                            input: { ...values }
                        }
                    }).then(({ data }) => {
                        if (!_this2._unmounted)
                            this.setState({
                                saving: false,
                                modalVisible: false
                            });

                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({ saving: false });
                    });
            }
            else {
                this.setState({ saving: false });
            }
        });

        // CreateProceeding({ variables: { input } })
        // createProceeding
        // this.setState({ modalVisible: false });

    }
    // onChangeType1 = (value) => {
    //     this.setState({ 'newType1': value })
    // }
    // onChangeType2 = (value) => {
    //     this.setState({ 'newType2': value })
    // }

    // onChangeTitle = (e) => {
    //     this.setState({ 'newTitle': e.target.value })
    // }
    // onChangeDesc = (e) => {
    //     this.setState({ 'newDesc': e.target.value })
    // }

    render() {
        const { saving } = this.state
        const { getFieldDecorator } = this.props.form;
        return (
            <Fragment>
                <Button type="primary" onClick={() => this.setModalVisible(true)}><Icon type="plus" /> Dodaj postępowanie</Button>
                <Mutation 
                    mutation={ gql(createProceeding) }
                    update={(cache, data) => {
                          const ce  = cache.readQuery({ query: gql(listProceedings) });
                          let newData = {...ce}
                          newData.listProceedings.items.unshift(data.data.createProceeding)
                          cache.writeQuery({
                                query: gql(listProceedings),
                                data: newData
                          });
                          notification['success']({
                              duration: 2,
                              message: 'Postępowanie zostało dodane',
                              description: 'Data operacji: ' + moment().format('YYYY-MM-DD hh:mm'),
                          });
                    }}
                >
                {(CreateProceeding, { data }) => (
                    <Modal
                      title="Nowe postępowanie"
                      centered
                      visible={this.state.modalVisible}
                      onOk={() => this.createProceeding(CreateProceeding)}
                      onCancel={() => this.setModalVisible(false)}
                      okButtonProps={{ loading: saving, icon: "save" }}
                    >
                    <Form>
                        <Form.Item label="Nazwa postępowania"> 
                        {
                            getFieldDecorator("title", {
                              rules: [{
                                required: true,
                                message: "Nazwa postępowania jest wymagana"
                              }]
                            })(<TextArea placeholder="Nazwa" autosize />)
                          } 
                        </Form.Item>
                        <Form.Item label="Opis postępowania">
                         {
                            getFieldDecorator("desc", {
                            })(<TextArea placeholder="Opis" autosize={{ minRows: 4, maxRows: 8 }} />)
                          } 
                        </Form.Item>
                        <Form.Item label="Tryp postępowania">
                        {
                            getFieldDecorator("type1", {
                              rules: [{
                                required: true,
                                message: "Tryb postępowania jest wymagana"
                              }]
                            })(<Select placeholder="Wybierz..."  style={{ width: 280 }}>
                                <Option value="1">przetarg nieograniczony</Option>
                                <Option value="2">przetarg ograniczony</Option>
                                <Option value="3">negocjacje z ogłoszeniem</Option>
                                <Option value="4">dialog konkurencyjny</Option>
                                <Option value="5">negocjacje bez ogłoszenia</Option>
                                <Option value="6">zamówienia z wolnej ręki</Option>
                                <Option value="7">zapytanie o cenę</Option>
                                <Option value="8">licytacja elektroniczna</Option>
                                <Option value="9">partnerstwo innowacyjne</Option>
                            </Select>)
                          } 
                        
                        </Form.Item>
                        <Form.Item label="Protokół dotyczy">
                        {
                            getFieldDecorator("type2", {
                              rules: [{
                                required: true,
                                message: "Pole jest wymagane"
                              }]
                            })(<Select placeholder="Wybierz..."  style={{ width: 280 }}>
                                <Option value="1">zamówienia publicznego</Option>
                                <Option value="2">umowy ramowej</Option>
                            </Select>)
                          } 
                        
                        </Form.Item>
                        <Form.Item>
                          {getFieldDecorator("step", { initialValue: 1 })(
                            <Input type="hidden" />
                          )}
                        </Form.Item>
                      </Form>
                    </Modal>
                )}
                </Mutation>
            </Fragment>
        )
    }

}

NewProc = Form.create()(NewProc);


const CopyEle = (props) => (
    <Mutation 
        mutation={ gql(createProceeding) }
        update={(cache, data) => {
            
          const ce  = cache.readQuery({ query: gql(listProceedings) });
          let newData = {...ce}
          
          newData.listProceedings.items.unshift(data.data.createProceeding)
          
          cache.writeQuery({
                query: gql(listProceedings),
                data: newData
          });
          notification['success']({
              duration: 2, message: 'Postępowanie zostało skopiowane',
              description: 'Data operacji: ' + moment().format('YYYY-MM-DD hh:mm'),
          });
    }}>
    {(createProceeding, { data }) => {
    
        let proceeding = {...props.proceeding}
        delete(proceeding.id)
        delete(proceeding.__typename)
        delete(proceeding.ownerId)
        delete(proceeding.update)
        delete(proceeding.create)
        delete(proceeding.lastModified)
        delete(proceeding.lastModifiedBy)
        
        Object.keys(proceeding).forEach((key)=>{if(proceeding[key]===null) delete(proceeding[key]) })
       
        return(
        <Popconfirm 
            title="Wykonać kopię postępowania?" 
            onConfirm={()=>(createProceeding({variables: {input: proceeding }} ))} 
            okText="Tak" cancelText="Nie"
            icon={<Icon type="info-circle" />}
            ><Icon type="copy" /></Popconfirm>
    )}}
    </Mutation>
)


const DeleteEle = (props) => (
    <Mutation 
        mutation={ gql(deleteProceeding) }
        update={(cache, data) => {
          
          const ce  = cache.readQuery({ query: gql(listProceedings) });
          
        //   const { items } = ce.listProceedings
          let newData = {...ce}
          newData.listProceedings.items = newData.listProceedings.items.filter(p => p.id !== data.data.deleteProceeding.id)
          
          
          cache.writeQuery({
                query: gql(listProceedings),
                data: newData
          });
          notification['success']({
              duration: 2,
              message: 'Postępowanie zostało usunięte',
              description: 'Data operacji: ' + moment().format('YYYY-MM-DD hh:mm'),
          });
    }}>
    {(deleteProceeding, { data }) => (
        <Popconfirm 
            title="Czy na pewno usunąć? Operacja jest nieodwracalna." 
            onConfirm={()=>(deleteProceeding({variables: {input: { id: props.id }}} ))} 
            okText="Tak" cancelText="Nie"
            icon={<Icon type="exclamation-circle" style={{ color: 'red' }} />}
            ><Icon type="delete" /></Popconfirm>
    )}
    </Mutation>
)

class ProceedingEdit extends Component {
    state = { saving: null }

    onSubmit(e, form, updateProceeding) {
        e.preventDefault();
        this.setState({ saving: true });
        const _this2 = this;

        form.validateFields((err, values) => {
            console.log(values)
            if (!err) {
                updateProceeding({
                        variables: {
                            input: { ...values, id: this.props.proceeding.id }
                        }
                    })
                    .then(({ data }) => {
                        if (!_this2._unmounted)
                            this.setState({
                                saving: false
                            });
                        this.props.handleOk()
                        notification['success']({
                            duration: 2,
                            message: 'Zmiany zostały zapisane',
                            description: 'Data operacji: ' + moment().format('YYYY-MM-DD hh:mm'),
                        });
                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({ saving: false });
                    });
            }
            else {
                this.setState({ saving: false });
            }
        });
    }

    render() {
        const { saving } = this.state
        const { toUpdate } = this.props
        const { getFieldDecorator } = this.props.form
        return (
            <Modal
              title="Edycja postępowania"
              visible={this.props.visible}
              onCancel={this.props.handleCancel}
              footer={null}
            >
            <Mutation mutation={ gql(updateProceeding) } >
            {(updateProceeding) => (
                <Form method="POST" onSubmit={(e)=>this.onSubmit(e, this.props.form, updateProceeding)}>
                     <FormItem label="Nazwa">
                    {
                      getFieldDecorator("title", {
                        initialValue:this.props.proceeding.title,
                        rules: [{
                           required: true,
                           message: "Pole jest wymagane"
                         }]
                      })(
                      <Input disabled={saving}/>
                      )
                    } 
                    </FormItem>
                    <FormItem label="Opis">
                    {
                      getFieldDecorator("desc", {
                        initialValue: this.props.proceeding.desc,
                        rules: [{
                           required: true,
                           message: "Pole jest wymagane"
                         }]
                      })(
                      <Input.TextArea disabled={saving}/>
                      )
                    } 
                    </FormItem>
                    <FormItem label="Priorytet">
                    {
                      getFieldDecorator("priority", {
                        initialValue: this.props.proceeding.priority || "medium",
                        rules: []
                      })(
                      <Radio.Group>
                          <Radio.Button value="low">niski</Radio.Button>
                          <Radio.Button value="medium">standardowy</Radio.Button>
                          <Radio.Button value="high">wysoki</Radio.Button>
                      </Radio.Group>
                      )
                    } 
                    </FormItem>
                    
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="save__button"
                        icon="save"
                        loading={saving}
                        disabled={!toUpdate}
                        style={{ float: 'right', marginBottom: 20}}
                        
                    > Zapisz zmiany </Button>
                    <br/><br/>
                </Form>
            )}
            </Mutation>
        </Modal>
        )
    }

}

const ProceedingEditForm = Form.create({
    onValuesChange: (props, changedValues, allValues) => props.setToUpdate(true)
})(ProceedingEdit);



class ProceedingList extends Component {
    state = { visible: false, proceeding: null, toUpdate: null }

    setToUpdate = (toUpdate) => {
        this.setState({ toUpdate })
    }

    showModal = (p) => {
        this.setState({
            visible: true,
            proceeding: p
        });
    }

    handleOk = (e) => {
        console.log(e);
        this.setState({
            visible: false,
            toUpdate: false
        });
    }

    handleCancel = (e) => {
        console.log(e);
        this.setState({
            visible: false,
            toUpdate: false
        });
    }
    render() {
        let vars = null
        if (this.props.search) vars = { filter: { title: { contains: this.props.search } } }

        return (
            <Fragment>
                <Query 
                    query={ gql(listProceedings) }
                    variables={vars}
                    fetch-police={'cache-first'}
                    >{({loading, data}) => {
                        if(loading) return <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}/>
                
                        const items = [].concat(data.listProceedings.items)
                        return(
                            items.reverse().map( (p, i) => (
                            <Col span={6} key={i} style={{ marginBottom: 20 }}>
                             <Card
                                hoverable
                                className='Proceeding'
                                title={ <Link to={'/postepowanie/'+p.id} title='edytuj'>{p.title}</Link>}
                                actions={[
                                    <Link to={'/postepowanie/'+p.id} title='wypełnij'><Icon type="edit" /></Link>,
                                    <Icon type="setting" onClick={()=>this.showModal(p)} />,
                                     <CopyEle proceeding={p} />,
                                     <DeleteEle id={p.id} />,
                            ]}>
                            {p.desc && <div className='desc' title={p.desc} ><Link to={'/postepowanie/'+p.id}>{p.desc.text_truncate(68)}</Link></div>}
                            <div className="proceeding__Info">
                                <label>Data utworzenia:</label> { moment.unix(p.create).format("YYYY-MM-DD HH:mm") }
                                <br /><label>Data modyfikacji:</label> { p.lastModified ? moment.unix(p.lastModified).format("YYYY-MM-DD HH:mm"): '-' }
                                <br /><label>Zmodyfikowany przez:</label> { p.lastModifiedBy ? p.lastModifiedBy: '-' }
                            </div>
                            <Tag  color={ p.priority==="high"? "red": p.priority==="low" ? "geekblue": "orange" }>Priorytet: {p.priority==="high"?"wysoki": p.priority==="low" ?  "niski": "standardowy" } </Tag>
                        
                            </Card>
                          </Col>
                        ) ) )
                
            }}
        </Query>
        <ProceedingEditForm toUpdate= {this.state.toUpdate } setToUpdate={this.setToUpdate} handleOk={this.handleOk} handleCancel={this.handleCancel} visible={this.state.visible} proceeding={this.state.proceeding} />
        </Fragment>
        )
    }
}




export default class Proceeding extends Component {

    constructor(props) {
        super(props);
        this.state = {
            search: null
        }
        this.onSearch = this.onSearch.bind(this)
    }


    onSearch(e) {
        this.setState({ search: e.target.value })
    }
    onSearch2(e) {
        this.setState({ search: e })
    }
    render() {
        const { search } = this.state

        return (
            <Fragment>
            <Row gutter={16} style={{ padding: 20, marginTop: 20 }}>
                <Col span={24}>
                     <NewProc />
                    <Search
                      style={{width: 400, marginLeft: 10}}
                      placeholder="Nazwa postepowania"
                      enterButton="Szukaj"
                    //   onChange={this.onSearch}
                      onSearch={value => this.onSearch2(value)}
                    />
                </Col>
            </Row>
            <Row gutter={16} style={{ padding: 20 }}>
                <ProceedingList search={search}/>
            </Row></Fragment>
        )
    }
}
