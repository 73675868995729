// @flow
// this is an auto generated file. This will be overwritten

export const onCreateUserServices = `subscription OnCreateUserServices(
  $userId: String
  $dropbox: Boolean
  $gdrive: Boolean
  $onedrive: Boolean
  $box: Boolean
) {
  onCreateUserServices(
    userId: $userId
    dropbox: $dropbox
    gdrive: $gdrive
    onedrive: $onedrive
    box: $box
  ) {
    userId
    dropbox
    gdrive
    onedrive
    box
  }
}
`;
export const onUpdateUserServices = `subscription OnUpdateUserServices(
  $userId: String
  $dropbox: Boolean
  $gdrive: Boolean
  $onedrive: Boolean
  $box: Boolean
) {
  onUpdateUserServices(
    userId: $userId
    dropbox: $dropbox
    gdrive: $gdrive
    onedrive: $onedrive
    box: $box
  ) {
    userId
    dropbox
    gdrive
    onedrive
    box
  }
}
`;
export const onDeleteUserServices = `subscription OnDeleteUserServices(
  $userId: String
  $dropbox: Boolean
  $gdrive: Boolean
  $onedrive: Boolean
  $box: Boolean
) {
  onDeleteUserServices(
    userId: $userId
    dropbox: $dropbox
    gdrive: $gdrive
    onedrive: $onedrive
    box: $box
  ) {
    userId
    dropbox
    gdrive
    onedrive
    box
  }
}
`;
export const onCreateUserMail = `subscription OnCreateUserMail($userId: String, $email: String) {
  onCreateUserMail(userId: $userId, email: $email) {
    userId
    email
  }
}
`;
export const onUpdateUserMail = `subscription OnUpdateUserMail($userId: String, $email: String) {
  onUpdateUserMail(userId: $userId, email: $email) {
    userId
    email
  }
}
`;
export const onDeleteUserMail = `subscription OnDeleteUserMail($userId: String, $email: String) {
  onDeleteUserMail(userId: $userId, email: $email) {
    userId
    email
  }
}
`;
export const onCreateProceeding = `subscription OnCreateProceeding(
  $ownerId: String
  $id: Int
  $type1: Int
  $type2: Int
  $title: String
) {
  onCreateProceeding(
    ownerId: $ownerId
    id: $id
    type1: $type1
    type2: $type2
    title: $title
  ) {
    ownerId
    id
    type1
    type2
    title
    desc
    create
    lastModified
    lastModifiedBy
    step
    priority
    orgType
    name
    street
    no
    no2
    zip
    city
    coutry
    phone
    fax
    email
    basedOn
    field21
    field22
    field22Reason
    field22Parts
    field23
    field23Int
    field23Int2
    field24Type1PLN
    field24Type2PLN
    field24Type3PLN
    field24Type4PLN
    field24Type51PLN
    field24Type52PLN
    field24Type1EUR
    field24Type2EUR
    field24Type3EUR
    field24Type4EUR
    field24Type51EUR
    field24Type52EUR
    field24Type1
    field24Type2
    field24Type3
    field24Type4
    field24Type5
    field25Date
    field25BasedOn
    field26
    field26Proc
    field26Prog
    field31
    field31Companies
    field31Influence
    field32
    field32Action
    field41
    field41Assistant
    field41AssistantName
    field41AssistantTasks
    field42
    field42Data
    field42BasedOn
    field42Members
    field42nCMembers
    field43
    field43Data
    field43Members
    field43nCMembers
    field44
    field44Members
    field45
    field45Members
    field4B1Desc
    field4B2Value
    field4B3DescJoin
    field4B4Criteria
    field4B5Other
    field4C
    field4D
    field4DDesc
    field5
    field51
    field51In
    field51Date
    field52
    field52Info
    field52Date
    field61
    field61Date
    field61Date2
    field61No
    field62
    field63Url
    field63From
    field63To
    field64
    field65
    field65Desc
    field66
    field66Comp
    field67
    field67Date
    field67No
    field67NoDate
    field68
    field7
    field7Url
    field7From
    field7To
    field71
    field71Date
    field71Desc
    field8
    field8Desc
    field9
    field101
    field101Reason
    field102
    field103
    field103Time
    field103Expand
    field103ExpandDate
    field104
    field111Date
    field111In
    field111Addr
    field112Ammount
    field112Parts
    field113
    field121
    field121Variants
    field122
    field131
    field131Names
    field132
    field132Names
    field141
    field142
    field151
    field151NamesReason
    field152
    field152Names
    field153
    field153Names
    field154
    field161
    field162
    field171
    field171Names
    field181
    field181Net
    field181Desc
    field182
    field1821
    field183
    field184
    field190
    field201
    field201Date
    field201People
    field202
    field202Date
    field210
    field21Date
    field21Form
    field220
    field2201
    field231
    field231Name
    field231Info
    field2311
    field2311Name
    field2311Info
    field2311Date
    field232
    field233
    fiedl233Desc
    field234
    field240
    field24Names
    field2510
    field251Date
    field251Names
    field2520
    field252Date
    field261Date
    field261With
    field261Value
    field261Subject
    field262
    field262Date
    field262No
    field262NoDate
    field270
    field281
    field281Name
    field281Name2
    field282
    field290
    field30Name
    field30Place
    field30Date
    fieldexs1
    fieldexs2
    fieldexs3
    fieldexs4
    fieldexs5
    field260Files
    field180Files
    field61Files
    field67Files
    field42Files
    field43Files
  }
}
`;
export const onUpdateProceeding = `subscription OnUpdateProceeding($id: Int) {
  onUpdateProceeding(id: $id) {
    ownerId
    id
    type1
    type2
    title
    desc
    create
    lastModified
    lastModifiedBy
    step
    priority
    orgType
    name
    street
    no
    no2
    zip
    city
    coutry
    phone
    fax
    email
    basedOn
    field21
    field22
    field22Reason
    field22Parts
    field23
    field23Int
    field23Int2
    field24Type1PLN
    field24Type2PLN
    field24Type3PLN
    field24Type4PLN
    field24Type51PLN
    field24Type52PLN
    field24Type1EUR
    field24Type2EUR
    field24Type3EUR
    field24Type4EUR
    field24Type51EUR
    field24Type52EUR
    field24Type1
    field24Type2
    field24Type3
    field24Type4
    field24Type5
    field25Date
    field25BasedOn
    field26
    field26Proc
    field26Prog
    field31
    field31Companies
    field31Influence
    field32
    field32Action
    field41
    field41Assistant
    field41AssistantName
    field41AssistantTasks
    field42
    field42Data
    field42BasedOn
    field42Members
    field42nCMembers
    field43
    field43Data
    field43Members
    field43nCMembers
    field44
    field44Members
    field45
    field45Members
    field4B1Desc
    field4B2Value
    field4B3DescJoin
    field4B4Criteria
    field4B5Other
    field4C
    field4D
    field4DDesc
    field5
    field51
    field51In
    field51Date
    field52
    field52Info
    field52Date
    field61
    field61Date
    field61Date2
    field61No
    field62
    field63Url
    field63From
    field63To
    field64
    field65
    field65Desc
    field66
    field66Comp
    field67
    field67Date
    field67No
    field67NoDate
    field68
    field7
    field7Url
    field7From
    field7To
    field71
    field71Date
    field71Desc
    field8
    field8Desc
    field9
    field101
    field101Reason
    field102
    field103
    field103Time
    field103Expand
    field103ExpandDate
    field104
    field111Date
    field111In
    field111Addr
    field112Ammount
    field112Parts
    field113
    field121
    field121Variants
    field122
    field131
    field131Names
    field132
    field132Names
    field141
    field142
    field151
    field151NamesReason
    field152
    field152Names
    field153
    field153Names
    field154
    field161
    field162
    field171
    field171Names
    field181
    field181Net
    field181Desc
    field182
    field1821
    field183
    field184
    field190
    field201
    field201Date
    field201People
    field202
    field202Date
    field210
    field21Date
    field21Form
    field220
    field2201
    field231
    field231Name
    field231Info
    field2311
    field2311Name
    field2311Info
    field2311Date
    field232
    field233
    fiedl233Desc
    field234
    field240
    field24Names
    field2510
    field251Date
    field251Names
    field2520
    field252Date
    field261Date
    field261With
    field261Value
    field261Subject
    field262
    field262Date
    field262No
    field262NoDate
    field270
    field281
    field281Name
    field281Name2
    field282
    field290
    field30Name
    field30Place
    field30Date
    fieldexs1
    fieldexs2
    fieldexs3
    fieldexs4
    fieldexs5
    field260Files
    field180Files
    field61Files
    field67Files
    field42Files
    field43Files
  }
}
`;
export const onDeleteProceeding = `subscription OnDeleteProceeding(
  $ownerId: String
  $id: Int
  $type1: Int
  $type2: Int
  $title: String
) {
  onDeleteProceeding(
    ownerId: $ownerId
    id: $id
    type1: $type1
    type2: $type2
    title: $title
  ) {
    ownerId
    id
    type1
    type2
    title
    desc
    create
    lastModified
    lastModifiedBy
    step
    priority
    orgType
    name
    street
    no
    no2
    zip
    city
    coutry
    phone
    fax
    email
    basedOn
    field21
    field22
    field22Reason
    field22Parts
    field23
    field23Int
    field23Int2
    field24Type1PLN
    field24Type2PLN
    field24Type3PLN
    field24Type4PLN
    field24Type51PLN
    field24Type52PLN
    field24Type1EUR
    field24Type2EUR
    field24Type3EUR
    field24Type4EUR
    field24Type51EUR
    field24Type52EUR
    field24Type1
    field24Type2
    field24Type3
    field24Type4
    field24Type5
    field25Date
    field25BasedOn
    field26
    field26Proc
    field26Prog
    field31
    field31Companies
    field31Influence
    field32
    field32Action
    field41
    field41Assistant
    field41AssistantName
    field41AssistantTasks
    field42
    field42Data
    field42BasedOn
    field42Members
    field42nCMembers
    field43
    field43Data
    field43Members
    field43nCMembers
    field44
    field44Members
    field45
    field45Members
    field4B1Desc
    field4B2Value
    field4B3DescJoin
    field4B4Criteria
    field4B5Other
    field4C
    field4D
    field4DDesc
    field5
    field51
    field51In
    field51Date
    field52
    field52Info
    field52Date
    field61
    field61Date
    field61Date2
    field61No
    field62
    field63Url
    field63From
    field63To
    field64
    field65
    field65Desc
    field66
    field66Comp
    field67
    field67Date
    field67No
    field67NoDate
    field68
    field7
    field7Url
    field7From
    field7To
    field71
    field71Date
    field71Desc
    field8
    field8Desc
    field9
    field101
    field101Reason
    field102
    field103
    field103Time
    field103Expand
    field103ExpandDate
    field104
    field111Date
    field111In
    field111Addr
    field112Ammount
    field112Parts
    field113
    field121
    field121Variants
    field122
    field131
    field131Names
    field132
    field132Names
    field141
    field142
    field151
    field151NamesReason
    field152
    field152Names
    field153
    field153Names
    field154
    field161
    field162
    field171
    field171Names
    field181
    field181Net
    field181Desc
    field182
    field1821
    field183
    field184
    field190
    field201
    field201Date
    field201People
    field202
    field202Date
    field210
    field21Date
    field21Form
    field220
    field2201
    field231
    field231Name
    field231Info
    field2311
    field2311Name
    field2311Info
    field2311Date
    field232
    field233
    fiedl233Desc
    field234
    field240
    field24Names
    field2510
    field251Date
    field251Names
    field2520
    field252Date
    field261Date
    field261With
    field261Value
    field261Subject
    field262
    field262Date
    field262No
    field262NoDate
    field270
    field281
    field281Name
    field281Name2
    field282
    field290
    field30Name
    field30Place
    field30Date
    fieldexs1
    fieldexs2
    fieldexs3
    fieldexs4
    fieldexs5
    field260Files
    field180Files
    field61Files
    field67Files
    field42Files
    field43Files
  }
}
`;
export const onCreateMails = `subscription OnCreateMails($to: String) {
  onCreateMails(to: $to) {
    id
    to
    subject
    body
    from
    from_name
    time
    attachments
  }
}
`;
export const onUpdateMails = `subscription OnUpdateMails(
  $id: Int
  $to: String
  $subject: String
  $body: String
  $from: String
) {
  onUpdateMails(id: $id, to: $to, subject: $subject, body: $body, from: $from) {
    id
    to
    subject
    body
    from
    from_name
    time
    attachments
  }
}
`;
export const onDeleteMails = `subscription OnDeleteMails(
  $id: Int
  $to: String
  $subject: String
  $body: String
  $from: String
) {
  onDeleteMails(id: $id, to: $to, subject: $subject, body: $body, from: $from) {
    id
    to
    subject
    body
    from
    from_name
    time
    attachments
  }
}
`;
export const onCreateFiles = `subscription OnCreateFiles(
  $id: Int
  $userId: String
  $url: String
  $name: String
  $projectId: String
) {
  onCreateFiles(
    id: $id
    userId: $userId
    url: $url
    name: $name
    projectId: $projectId
  ) {
    id
    userId
    hash
    sigKey
    name
    projectId
    size
    type
    role
    comment
    added
    key
  }
}
`;
export const onUpdateFiles = `subscription OnUpdateFiles(
  $id: Int
  $userId: String
  $url: String
  $name: String
  $projectId: String
) {
  onUpdateFiles(
    id: $id
    userId: $userId
    url: $url
    name: $name
    projectId: $projectId
  ) {
    id
    userId
    hash
    sigKey
    name
    projectId
    size
    type
    role
    comment
    added
    key
  }
}
`;
export const onDeleteFiles = `subscription OnDeleteFiles(
  $id: Int
  $userId: String
  $url: String
  $name: String
  $projectId: String
) {
  onDeleteFiles(
    id: $id
    userId: $userId
    url: $url
    name: $name
    projectId: $projectId
  ) {
    id
    userId
    hash
    sigKey
    name
    projectId
    size
    type
    role
    comment
    added
    key
  }
}
`;
export const onUpdateOrganization = `subscription OnUpdateOrganization(
  $userId: String
  $orgId: String
  $name: String
  $address: String
  $telephone: String
) {
  onUpdateOrganization(
    userId: $userId
    orgId: $orgId
    name: $name
    address: $address
    telephone: $telephone
  ) {
    userId
    orgId
    userName
    userEmail
    userPhone
    name
    address
    telephone
    email
    isOwner
    perms
    confirmed
    ownerId
    attr
  }
}
`;
export const onUpdateChain = `subscription OnUpdateChain(
  $id: String
  $ownerId: String
  $json: String
  $pickle: String
  $lastModified: Int
) {
  onUpdateChain(
    id: $id
    ownerId: $ownerId
    json: $json
    pickle: $pickle
    lastModified: $lastModified
  ) {
    id
    ownerId
    json
    pickle
    lastModified
  }
}
`;
export const onCreateFieldsInfo = `subscription OnCreateFieldsInfo(
  $proceedingId: String
  $fieldName: String
  $editCount: String
  $lastEdited: AWSDateTime
) {
  onCreateFieldsInfo(
    proceedingId: $proceedingId
    fieldName: $fieldName
    editCount: $editCount
    lastEdited: $lastEdited
  ) {
    proceedingId
    fieldName
    editCount
    lastEdited
  }
}
`;
export const onUpdateFieldsInfo = `subscription OnUpdateFieldsInfo(
  $proceedingId: String
  $fieldName: String
  $editCount: String
  $lastEdited: AWSDateTime
) {
  onUpdateFieldsInfo(
    proceedingId: $proceedingId
    fieldName: $fieldName
    editCount: $editCount
    lastEdited: $lastEdited
  ) {
    proceedingId
    fieldName
    editCount
    lastEdited
  }
}
`;
export const onDeleteFieldsInfo = `subscription OnDeleteFieldsInfo(
  $proceedingId: String
  $fieldName: String
  $editCount: String
  $lastEdited: AWSDateTime
) {
  onDeleteFieldsInfo(
    proceedingId: $proceedingId
    fieldName: $fieldName
    editCount: $editCount
    lastEdited: $lastEdited
  ) {
    proceedingId
    fieldName
    editCount
    lastEdited
  }
}
`;
