import React, { Component } from 'react';
import { Tabs, Form, Button, Input, Spin, Icon, notification, Modal, Table, Checkbox, Tag } from 'antd';
import { Query, Mutation } from 'react-apollo'
import { getOrganization, listOrganizations } from 'queries'
import { updateOrganization } from 'mutations'
import gql from "graphql-tag";
import moment from 'moment'

const FormItem = Form.Item
const TabPane = Tabs.TabPane;


class UserPrems extends Component {
    state = {
        saving: false
    }

    onSubmit(e, form, UpdateOrganization) {
        e.preventDefault();
        this.setState({ saving: true });
        const _this2 = this;

        form.validateFields((err, values) => {

            if (!err) {
                UpdateOrganization({
                        variables: {
                            input: {
                                perms: values.perms,
                                userId: this.props.userId
                            }
                        }
                    })
                    .then(({ data }) => {
                        if (!_this2._unmounted)
                            this.setState({
                                saving: false
                            });
                        this.props.setToUpdate(false)
                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({ saving: false });
                    });
            }
            else {
                this.setState({ saving: false });
            }
        });
    }

    render() {
        const { perms, userId, toUpdate } = this.props
        const { getFieldDecorator } = this.props.form
        const { saving } = this.state
        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 14 },
        };
        return (
            <Mutation 
                mutation={ gql(updateOrganization) }
                update={(cache, data) => {
                //   const filter={}
                //  const ce = cache.readQuery({ query: gql(getOrganization), variables: { userId } });  
                //   const { items } = ce.listProceedings
                  let newData = { ...data.updateOrganization }
                  
                  cache.writeQuery({
                      query: gql(getOrganization),
                      variables: { userId },
                      data: newData
                  });
                  notification['success']({
                      duration: 2,
                      message: 'Zmiany zostały zapisane',
                      description: 'Data operacji: ' + moment().format('YYYY-MM-DD hh:mm'),
                  });
                }}
            >
            {(updateOrganization) => (
                <Form method="POST" onSubmit={(e)=>this.onSubmit(e, this.props.form, updateOrganization)}>
                     <Form.Item
                      {...formItemLayout}
                      label="Uprawnienia"
                    >
                      {getFieldDecorator("perms", {
                        initialValue: perms,
                      })(
                        <Checkbox.Group style={{ width: "100%" }}>
                            <Checkbox value="hide_projects">Ukryj projekty</Checkbox><br/>
                            <Checkbox value="hide_files">Ukryj pliki</Checkbox><br/>
                            <Checkbox value="hide_messages">Ukryj wiadomości</Checkbox><br/>
                            <Checkbox value="hide_cal">Ukryj kalendarz</Checkbox><br/>
                            <Checkbox value="hide_organization">Ukryj ustawienia</Checkbox>
                        </Checkbox.Group>
                      )}
                    </Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="save__button"
                        icon="save"
                        loading={saving}
                        disabled={!toUpdate}
                    > Zapisz </Button>
                </Form>
            )}
            </Mutation>
        )
    }


}

const UserPermsForm = Form.create({
    onValuesChange: (props, changedValues, allValues) => props.setToUpdate(true)
})(UserPrems);


class OrgUser extends Component {
    state = {
        saving: false
    }

    onSubmit(e, form, UpdateOrganization) {
        e.preventDefault();
        this.setState({ saving: true });
        const _this2 = this;

        form.validateFields((err, values) => {
            let org = { ...this.props.org }
            console.log(org)
            if (!err) {
                UpdateOrganization({
                        variables: {
                            input: {
                                name: org.name || '',
                                address: org.address || '',
                                telephone: org.telephone || '',
                                email: org.email || '',
                                ...values,
                                orgId: org.orgId,
                                ownerId: org.userId,
                                confirmed: true,
                                isOwner: false
                            }
                        }
                    })
                    .then(({ data }) => {
                        if (!_this2._unmounted)
                            this.setState({
                                saving: false
                            });
                        this.props.handleOk()
                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({ saving: false });
                    });
            }
            else {
                this.setState({ saving: false });
            }
        });
    }

    render() {
        const { toUpdate } = this.props
        const { getFieldDecorator } = this.props.form
        const { saving } = this.state

        return (
            <Mutation 
                mutation={ gql(updateOrganization) }
                update={(cache, data) => {
                //   const filter={}
                  const ce = cache.readQuery({ query: gql(listOrganizations), variables:  {filter: { orgId: {eq: data.data.updateOrganization.orgId }} }});  
                //   const { items } = ce.listProceedings
                //   let newData = {...data.updateOrganization}
                  let newData = {...ce}
                  newData.listOrganizations.items.unshift(data.data.updateOrganization)
                
                  cache.writeQuery({
                        query: gql(listOrganizations),
                        variables:  {filter: { orgId: {eq: data.data.updateOrganization.orgId }}},
                        data: newData
                  });
                  notification['success']({
                      duration: 2,
                      message: 'Zmiany zostały zapisane',
                      description: 'Data operacji: ' + moment().format('YYYY-MM-DD hh:mm'),
                  });
            }}>
            {(updateOrganization) => (
                <Form method="POST" onSubmit={(e)=>this.onSubmit(e, this.props.form, updateOrganization)}>
                     <FormItem label="Podaj otrzymany kod">
                    {
                      getFieldDecorator("userId", {
                        rules: [{
                           required: true,
                           message: "Pole jest wymagane"
                         }]
                      })(
                      <Input disabled={saving}/>
                      )
                    } 
                    </FormItem>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="save__button"
                        icon="save"
                        loading={saving}
                        disabled={!toUpdate}
                    > Dodaj </Button>
                </Form>
            )}
            </Mutation>
        )
    }


}

const OrgUserForm = Form.create({
    onValuesChange: (props, changedValues, allValues) => props.setToUpdate(true)
})(OrgUser);

class Org extends Component {
    state = {
        saving: false,
        visible: false
    }

    onSubmit(e, form, UpdateOrganization) {
        e.preventDefault();
        this.setState({ saving: true });
        const _this2 = this;

        form.validateFields((err, values) => {

            if (!err) {
                UpdateOrganization({
                        variables: {
                            input: { ...values, userId: this.props.userId, isOwner: true, confirmed: true }
                        }
                    })
                    .then(({ data }) => {
                        if (!_this2._unmounted)
                            this.setState({
                                saving: false
                            });
                        this.props.setToUpdate(false)
                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({ saving: false });
                    });
            }
            else {
                this.setState({ saving: false });
            }
        });
    }
    copyToClipboard = (e) => {
        navigator.clipboard.writeText(e.target.innerText)
        notification['success']({
            duration: 1,
            message: 'Adres skopiowany do schowka',
        });

    };

    showModal = () => {
        this.setState({
            visible: true,
        });
    }

    hideModal = () => {
        this.setState({
            visible: false,
        });
    }

    render() {
        const { org, userId, toUpdate } = this.props
        const { getFieldDecorator } = this.props.form
        const { saving } = this.state

        return (
            <div style={{ padding: 20 }}>
            <Button
                        type="primary"
                        onClick = {this.showModal}
                        icon="save"
            > Dołącz do instniejącej organizacji </Button>
            <Modal
              title="Modal"
              visible={this.state.visible}
              onOk={this.hideModal}
              onCancel={this.hideModal}
              okText="Zamknij"
            >
                             
              <p>Twój kod to:</p>
              <Tag onClick={this.copyToClipboard}>{ userId }<Icon type="user" /></Tag>
              <p>Przekaż go administratorowi konta organizacji do której dołączasz. <br/>
                Uwaga! Po dodaniu do organizacji musisz się wylogować i zalogować ponownie.
              </p>
            </Modal>
            
            <Mutation 
                mutation={ gql(updateOrganization) }
                update={(cache, data) => {
                //   const filter={}
                //  const ce = cache.readQuery({ query: gql(getOrganization), variables: { userId } });  
                //   const { items } = ce.listProceedings
                  let newData = {...data.updateOrganization}
                
                  cache.writeQuery({
                        query: gql(getOrganization),
                        variables: {userId},
                        data: newData
                  });
                  notification['success']({
                      duration: 2,
                      message: 'Zmiany zostały zapisane',
                      description: 'Data operacji: ' + moment().format('YYYY-MM-DD hh:mm'),
                  });
            }}>
            {(updateOrganization) => (
                <Form method="POST" onSubmit={(e)=>this.onSubmit(e, this.props.form, updateOrganization)}>
                     <FormItem label="Nazwa organizacji">
                    {
                      getFieldDecorator("name", {
                        initialValue: this.props.org.name,
                        rules: [{
                           required: true,
                           message: "Pole jest wymagane"
                         }]
                      })(
                      <Input disabled={saving}/>
                      )
                    } 
                    </FormItem>
                    <FormItem label="Adres organizacji">
                    {
                      getFieldDecorator("address", {
                        initialValue: org.address,
                        rules: [{
                           required: true,
                           message: "Pole jest wymagane"
                         }]
                      })(
                      <Input disabled={saving}/>
                      )
                    } 
                    </FormItem>
                    <FormItem label="Telefon">
                    {
                      getFieldDecorator("telephone", {
                        initialValue: org.telephone,
                        rules: [{
                           required: true,
                           message: "Pole jest wymagane"
                         }]
                      })(
                      <Input disabled={saving}/>
                      )
                    } 
                    </FormItem>
                    <FormItem label="Email">
                    {
                      getFieldDecorator("email", {
                        initialValue: org.email,
                        rules: [{
                           required: true,
                           message: "Pole jest wymagane"
                         }]
                      })(
                      <Input disabled={saving}/>
                      )
                    } 
                    </FormItem>
                    
                    
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="save__button"
                        icon="save"
                        loading={saving}
                        disabled={!toUpdate}
                    > Zapisz zmiany </Button>
                </Form>
            )}
            </Mutation>
            </div>
        )
    }
}

const OrgForm = Form.create({
    onValuesChange: (props, changedValues, allValues) => props.setToUpdate(true)
})(Org);


export default class Settings extends Component {
    state = {
        toUpdate: false,
        visible: false
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    }

    handleOk = (e) => {
        this.setState({
            visible: false,
        });
    }
    handleCancel = (e) => {
        this.setState({
            visible: false,
        });
    }


    setToUpdate = (toUpdate) => {
        this.setState({ toUpdate })
    }

    render() {
        const { authData, refetch } = this.props

        const columns = [{
            title: 'Imię i nazwisko',
            dataIndex: 'userName',
            key: 'userName'
        }, {
            title: 'Telefon',
            dataIndex: 'userPhone',
            key: 'userPhone'
        }, {
            title: 'Email',
            dataIndex: 'userEmail',
            key: 'userEmail'
        }]


        return (
            <Query
                        query={ gql(getOrganization) }
                        variables = {{ userId: authData.username  }}
                        fetchPolicy={refetch ? 'cache-and-network': 'cache-first'}
                    >
                    {({ loading, data, fetchMore }) => {
            if (loading){
                const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
                return <Spin indicator={antIcon} className='spinner' />
            }
            return(
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Organizacja" key="1">
                        <OrgForm toUpdate={this.state.toUpdate} org={data.getOrganization} setToUpdate={this.setToUpdate} userId={authData.username}/>
                    </TabPane>
                    
                    {authData.org.isOwner &&
                    <TabPane tab="Użytkownicy" key="3">
                        <Button type="primary" onClick={this.showModal} style={{ margin: 20}}>
                          Dodaj użytkownika
                        </Button>
                        <Modal
                          title="Dodaj użytkownika"
                          visible={this.state.visible}
                          onOk={this.handleOk}
                          onCancel={this.handleCancel}
                        >
                          <OrgUserForm toUpdate={this.state.toUpdate} org={data.getOrganization} handleOk={this.handleOk} setToUpdate={this.setToUpdate} ownerId={authData.username}/>
                        </Modal>
                         <Query
                            query={ gql(listOrganizations) }
                            variables = {{ filter: { orgId: {eq: data.getOrganization.orgId }}}}
                            fetchPolicy={refetch ? 'cache-and-network': 'cache-first'}
                        >
                        {({ loading, data, fetchMore }) => {
                            if (loading){
                                const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
                                return <Spin indicator={antIcon} className='spinner' />
                                }
                            
                            console.log(data)
                            return (
                                <Table 
                                    expandedRowRender={record => <UserPermsForm toUpdate={this.state.toUpdate} perms={record.perms} setToUpdate={this.setToUpdate} userId={record.userId} />}
                                    columns={columns} 
                                    dataSource={data.listOrganizations.items} rowKey="userId"/>
                            )
                        }}
                        </Query>
                    </TabPane>
                    }
                </Tabs>
            )
        }}
        </Query>
        )
    }
}
