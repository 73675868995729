import React from 'react'

import { SignUp, Link } from 'aws-amplify-react';
import { Form, Input, Button, Checkbox, Select } from 'antd';
import { Auth } from 'aws-amplify'

const FormItem = Form.Item;
const Option = Select.Option;

// const RadioGroup = Radio.Group;


class CustomSignUp extends SignUp {
  state = {
    confirmDirty: false,
    genPass: null,
    signingUp: false,
    accept: false
  };

  handleSubmit = async(e) => {
    e.preventDefault();
    this.setState({ signingUp: true })
    this.props.form.validateFields((err, values) => {

      if (!err) {
        var _this3 = this;

        var { phone, prefix, password, email, name, family_name } = values

        const phone_number = '+' + prefix + phone

        // console.log(values)

        Auth.signUp({
          "username": phone_number,
          password,
          email,
          attributes: {
            phone_number,
            email,
            name,
            family_name
          }
        }).then(function() {
          _this3.setState({ signingUp: false })
          return _this3.changeState('confirmSignUp', phone_number);
        }).catch(function(err) {
          _this3.setState({ signingUp: false })
          return _this3.error(err);
        });
      }
      else {
        this.setState({ signingUp: false })
      }
    });
  }
  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }


  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    }
    else {
      callback();
    }
  }

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  }

  generatePass = (setFieldsValue) => {
    let num = Math.floor(Math.random() * (11 - 8) + 8)
    let pass = ''
    try {
      pass = Array(num)
        .fill('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!@#$%^&*')
        .map(x => x[Math.floor(window.crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1) * (x.length + 1))])
        .join('');
    }
    catch (e) {
      pass = Math.random().toString(32).substr(2, 8);
    }

    setFieldsValue({
      password: pass,
      confirm: pass
    })

    this.setState({ genPass: pass })
  }

  back = () => {
    window.reload();
  }


  showComponent = () => {
    if (this.props.authState !== 'signUp')
      return null;


    const {
      getFieldDecorator,
      setFieldsValue
    } = this.props.form;


    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };


    const prefixSelector = getFieldDecorator('prefix', {
      initialValue: '48',
    })(
      <Select style={{ width: 70 }}>
          <Option value="48">+48</Option>
          <Option value="49">+49</Option>
        </Select>
    );


    return (
      <Form onSubmit={this.handleSubmit} className="signup-form">
      <div className="logo">
              <img src="https://tlige.eu/assets/images/logo.png" alt="logo" className="img-responsive" />
            </div>
            <br/>
                  <FormItem
                    {...formItemLayout}
                    label="Telefon"
                  >
                    {getFieldDecorator('phone', {
                      initialValue: "",
                      rules: [{ required: true, message: 'Podaj numer telefonu!' }],
                    })(
                      <Input addonBefore={prefixSelector} style={{ width: '100%' }} />
                    )}
                  </FormItem>
                  
                  <FormItem
                    { ...formItemLayout }
                    label="E-mail" >
                      {
                        getFieldDecorator('email', {
                          initialValue: "",
                          rules: [{
                            type: 'email',
                            message: 'Wprowadź poprawny email',
                          }, {
                            required: true,
                            message: 'Podaj email',
                          }],
                        })(
                          <Input />
                        )
                      } 
                  </FormItem>
                   <FormItem
                    { ...formItemLayout }
                    label = "Imię" >
                      {
                        getFieldDecorator('name', {
                          rules: [{
                            required: true,
                            message: 'Podaj imię',
                          }],
                        })(
                          <Input />
                        )
                      } 
                  </FormItem>
                  
                  <FormItem
                    { ...formItemLayout }
                    label = "Nazwisko" >
                      {
                        getFieldDecorator('family_name', {
                          rules: [{
                            required: true,
                            message: 'Podaj nazwisko',
                          }],
                        })(
                          <Input />
                        )
                    } 
                    </FormItem>
                    
                    <FormItem
                    {...formItemLayout}
                    label="Hasło"
                  >
                    {getFieldDecorator('password', {
                      rules: [{
                        required: true, message: 'Wprowadź hasło!',
                      }, {
                        validator: this.validateToNextPassword,
                      }],
                    })(
                      <Input type="password" onBlur={this.handleConfirmBlur} />
                    )}
                  </FormItem>
                  <FormItem
                    {...formItemLayout}
                    label="Powtórz hasło"
                  >
                    {getFieldDecorator('confirm', {
                      rules: [{
                        required: true, message: 'Potwierdź hasło!',
                      }, {
                        validator: this.compareToFirstPassword,
                      }],
                    })(
                      <Input type="password" onBlur={this.handleConfirmBlur} />
                    )}
                  </FormItem>
                   <FormItem  {...formItemLayout} label="Generowanie hasła">
                    <Button onClick={()=>this.generatePass(setFieldsValue)}> Wygeneruj hasło losowe </Button>
                    {this.state.genPass && <div> Twoje hasło to: { this.state.genPass } </div> }
                  </FormItem>
                  
                  
                  <FormItem {...tailFormItemLayout}>
                  <Checkbox onChange={(e)=>this.setState({accept: e.target.checked})} >Akceptuje warunki <a rel="noopener noreferrer" href="https://tlige.eu/regulamin_portalu_TLIGE.pdf" target="_blank">regulaminu</a></Checkbox>
                   
                  </FormItem>
                  <FormItem {...tailFormItemLayout}>
                    <Button loading={this.state.signingUp} disabled={!this.state.accept} type="primary" htmlType="submit" className="login-form-button">Zarejestruj</Button>
                    <Link className="login-form-forgot" onClick={ ()=> { this.changeState('signIn')}}>Wróc do logowania</Link>
                  </FormItem>
      </Form>

    )
  }

  render() {
    return this.showComponent()
  }

}



export default Form.create()(CustomSignUp);
