import React, { Component } from 'react';
import { Spin, Icon, Tabs, Tag, notification, Row, Col, Tooltip } from 'antd';
// import AuctionEditForm from 'AuctionEditForm'
import ProceedingForm from 'ProceedingForm'

import { getProceeding, getChain, listMails } from 'queries'
import { onCreateMails, onUpdateProceeding } from 'subscriptions'
import { Query } from 'react-apollo'
import gql from "graphql-tag";
import MailsList from 'MailsList'
import ChainLog from 'ChainLog'
import FileList from 'FileList'
import FileAdd from 'FileAdd'
import { Storage } from 'aws-amplify';

const TabPane = Tabs.TabPane;


export default class Proceeding extends Component {
    state = {
        selected: null,
        at: 1
    }


    copyToClipboard = (e) => {
        navigator.clipboard.writeText(e.target.innerText)
        notification['success']({
            duration: 1,
            message: 'Skopiowane do schowka',
        });

    };

    mailSelect = (selected) => {
        this.setState({ selected })
    }

    downloadFile = (key) => {
        const _this = this
        Storage.get("temp/" + this.props.match.params.value + "/" + key)
            .then(url => {
                _this.linkRef.href = url
                _this.linkRef.click()
            })
    }


    render() {
        const { value } = this.props.match.params || null
        const { refetch, authData } = this.props
        const { selected, at } = this.state

        return (

            <Tabs   defaultActiveKey="1" 
                    onChange={(at)=>this.setState({at})}
                    className="proceeding__tabs" 
                    tabBarExtraContent={ at==='3' &&
                            <div className="page__Info"> 
                                <label> Dane kontaktowe dla uczestników postępowania: </label>
                                <Tag onClick={this.copyToClipboard}>{ value+"@tlige.eu" } <Icon type="mail" /></Tag>
                                <Tag onClick={this.copyToClipboard}>{ "https://kontakt.tlige.eu/?q="+value } <Icon type="global" /></Tag>
                            </div>}>
                    <TabPane tab="Postępowanie" key="1" style={{ background: '#f5f8fe' }}>
                    <Query 
                        query={ gql(getProceeding) }
                        variables={{ id: value }}
                        fetch-police={'no-cache'}
                        
                    >{({ subscribeToMore, ...result }) => {
                        if(result.loading) return <div style={{ minHeight: '85vh', paddingTop: '10vh'}}><Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}/></div>
                        
                        return (
                            <ProceedingForm 
                                proceeding={ result.data.getProceeding } 
                                subscribeToNew={() =>
                                      subscribeToMore({
                                        document:  gql(onUpdateProceeding),
                                        variables: { id:result.data.getProceeding.id },
                                        updateQuery: (prev, { subscriptionData }) => {
                                                // console.log(prev.getProceeding, subscriptionData.data.onUpdateProceeding)
                                                // if(subscriptionData.data.onUpdateProceeding.lastModifiedBy === authData.username){
                                                //     return prev
                                                // }
                                               
                                                
                                                
                                                return  Object.assign({}, prev, {
                                                        ...subscriptionData.data.onUpdateProceeding
                                                });
                                                // return prev
                                        }
                                      })
                                    }   
                            />
                    )}}</Query>    
                    </TabPane>
                    <TabPane tab="Pliki" key="2">
                        <div className='proc_fileAdd'><FileAdd items={[]} authData={authData} project={value}/></div>
                        <FileList project={value} username={authData.username} />
                    </TabPane>
                    <TabPane tab="Wiadomości" key="3">
                        <Row style={{ marginTop: '-6px', borderBottom: '1px solid #e8e8e8', }}>
                        <Col span={12} >
                        
                        <Query
                            query={ gql(listMails) }
                            variables={{ filter: { to: { eq: value }}}}
                            fetchPolicy={refetch ? 'cache-and-network': 'cache-first'}
                        >
                            {({ subscribeToMore, ...result }) => {
                                // console.log(result)
                                if(result.loading) return null
                            
                                return (
                                <MailsList 
                                    mails={result.data.listMails} loading={result.loading}
                                    onSelect={this.mailSelect}
                                    selected = {selected && selected.id}
                                    subscribeToNew={() =>
                                      subscribeToMore({
                                        document:  gql(onCreateMails) ,
                                            variables: { to : ""+value } ,
                                            updateQuery: (prev, { subscriptionData }) => {
                                                if (!subscriptionData.data) return prev;
                                                let newMail = subscriptionData.data.onCreateMails
                                                newMail.__typename = "mails"
                                                return Object.assign({}, prev, {
                                                    listMails: {...prev.listMails, items: [newMail, ...prev.listMails.items]}
                                                });
                                        }
                                      })
                                    }
                                                    
                                />)
                            }}
                        </Query>
                        </Col><Col span={12} style = {{ borderLeft: '1px solid #e8e8e8',  height: (window.innerHeight-180), background: '#f5f8fe' }}>
                           
                            { selected &&
                            <div className='item__email'>
                                <div className='item__emailHead'>
                                    <div className='item__emailFrom'><label>Od:</label> {selected.from_name} {selected.from && <span>({selected.from})</span>}</div>
                                    <div className='item__emailSubject'><label>Temat:</label> {selected.subject} </div>
                                </div>
                                <div className='item__emailBody'>
                                    <br/><label>Treść <Tooltip title="Kliknij na treść maila aby skopiować treść do schowka.">
                <Icon type="question-circle-o" />
              </Tooltip>:</label> <br/>
                                    <p onClick={this.copyToClipboard} title="kliknij aby zapisać treść do schowka" style={{ margin: 0,cursor: 'pointer' }} dangerouslySetInnerHTML={{__html: selected.body}}></p>
                                    
                                    { selected.attachments && <span><br/><br/><label>Załączniki:</label> <br/> 
                                        { selected.attachments.map((item,i)=><div className="item__att" onClick={()=>this.downloadFile(item)} key={i}>{ item }</div>) }
                                        {selected.attachments.length ===0 && <span>brak załączników </span>}
                                    </span>}
                                </div>
                               
                            </div>
                            }
                        </Col>
                        {/* eslint-disable-next-line */}
                        <a href="" style={{"dispaly":"none"}} download ref={el => (this.linkRef = el)} ></a>
                        </Row>
                    </TabPane>
                    <TabPane tab="Dziennik" key="4">
                        <br />
                        <Query 
                          query={ gql(getChain) }
                          variables={{ id: value }}
                        >{({loading, data: {getChain}, refetch}) => { 
                          if (loading) return <div style={{ minHeight: '85vh', paddingTop: '10vh'}}><Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}/></div>
                              return(
                                  <ChainLog refetch={refetch} chain={JSON.parse(getChain.json)} pending="Trwa edycja..." />
                              )}}
                        </Query>
                    </TabPane>
                </Tabs>




        );
    }
}
