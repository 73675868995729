import React from 'react'

import { SignIn, Link } from 'aws-amplify-react';
import { Form, Icon, Input, Button } from 'antd';
import { Auth, JS } from 'aws-amplify'

const FormItem = Form.Item;

class CustomSignIn extends SignIn {
  _vCP = null

  checkContact = (user) => {
    var _this2 = this;

    Auth.verifiedContact(user).then(function(data) {
      if (_this2._unmounted) return false

      if (!JS.isEmpty(data.verified)) {
        _this2.changeState('signedIn', user);
      }
      else {
        user = Object.assign(user, data);
        _this2.changeState('verifyContact', user);
      }


    });
  }

  componentWillUnmount() {
    this._unmounted = true
  }

  handleSubmit = async(e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {

      if (!err) {
        var _this3 = this;
        this.setState({ loggingIn: true })
        const { username, password } = values

        Auth.signIn(username, password).then(function(user) {
          // logger.debug(user);

          Auth.currentCredentials().then((credentials) => {
            Auth.updateUserAttributes(user, {
              'custom:identity_id': credentials.identityId
              // 'custom:orgId': "046433d3-a0e2-4040-b851-498e4e992472"
            }).then(() => {

              if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
                // logger.debug('confirm user with ' + user.challengeName);
                _this3.changeState('confirmSignIn', user);
              }
              else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                // logger.debug('require new password', user.challengeParam);
                _this3.changeState('requireNewPassword', user);
              }
              else if (user.challengeName === 'MFA_SETUP') {
                // logger.debug('TOTP setup', user.challengeParam);
                _this3.changeState('TOTPSetup', user);
              }
              else {
                _this3.checkContact(user);
                _this3.setState({ loggingIn: false })
              }


            })
          });

        }).catch(function(err) {
          _this3.setState({ loggingIn: false })
          _this3.error(err);
        });



      }
      else {
        _this3.setState({ loggingIn: false })
      }
      // Auth.signIn(this.inputs.username, this.inputs.password)
      // this.signIn()

      // this.timer = setTimeout( () => {  }, 1000 )

    });
  }


  showComponent = () => {
    const { getFieldDecorator } = this.props.form;
    const { loggingIn } = this.state
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    if (['signIn', 'signedOut', 'signedUp'].indexOf(this.props.authState) < 0)
      return null;

    return (
      <div>
       
      <Form onSubmit={this.handleSubmit} className="signin-form" method="POST">
            <div className="logo">
                          <img src="https://tlige.eu/assets/images/logo.png" alt="logo" className="img-responsive" />
                        </div> <br/>
              <FormItem {...formItemLayout} label="Numer telefonu">
                {getFieldDecorator('username', {
                  rules: [{ required: true, message: 'Podaj poprawny numer telefonu' }],
                })(
                  <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Użytkownik" key='username'
                      disabled={loggingIn} />
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="Hasło">
                {getFieldDecorator('password', {
                  rules: [{ required: true, message: 'Podaj hasło' }],
                })(
                  <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} key="password" type="password" 
                      placeholder="Hasło" disabled={loggingIn} />
                )}
              </FormItem>
                <div  style={{'textAlign': 'right'}}>
                  <Link
                    onClick={ ()=> { this.changeState('forgotPassword')}} >
                      Resetowanie hasła
                  </Link>
                </div><br/>
                <Button type="primary" htmlType="submit" icon="login" loading={loggingIn} className="login-form-button">
                  Logowanie
                </Button><br/><br/>
                Nie masz konta? <Link className="login-form-forgot" 
                  onClick={ ()=> { this.changeState('signUp')}} >
                    Utwórz nowe!
                </Link>
              <br/><br/>
            </Form>
        </div>
    )
  }

  render() {
    return this.showComponent()
  }

}
export default Form.create()(CustomSignIn);
