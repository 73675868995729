import React, { Component } from 'react';
import { Spin, Icon, List } from 'antd';

export default class MailsList extends Component {
    componentDidMount() {
        this.props.subscribeToNew();
        if (!this.props.selected && this.props.mails.items) {
            this.props.onSelect(this.props.mails.items[0])
        }
    }
    columns = [{
        title: 'Temat',
        dataIndex: 'subject',
    }, {
        title: 'Od',
        dataIndex: 'from_name',
    }];

    render() {
            const { loading, mails, onSelect, selected } = this.props



            // if (project) filter = { projectId: { eq: project } }
            // variables = {{ filter }}
            if (loading) {
                const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
                return <Spin indicator={antIcon} className='spinner' />
            }

            // return (<ul>  { mails.items && mails.items.map((mail,i)=><div key={i}>{ mail.subject }</div>) } </ul>)

            return (
                    <List
                    itemLayout="horizontal"
                    dataSource={mails.items}
                    renderItem={item => (
                      <List.Item onClick={()=>onSelect(item)} className={selected===item.id?'emails__listItem emails__selected':'emails__listItem'}>
                        <List.Item.Meta
                          title={<div className='emails__from'>{ item.from_name } { item.from && <span>({item.from})</span> }</div>}
                          description={<div className='emails__subject'>{item.subject}</div>}
                        />
                      </List.Item>
                    )}
            />)
            
            // return (<Table 
            //     onRow={(record, rowIndex) => {
            //         return {
            //           onClick: (event) => onSelect(event),       // click row
            //         };
            //       }}
            //     columns={this.columns} 
            //     dataSource={mails.items} 
            //     rowKey='id'
            //     expandedRowRender={record => }

            // />)
    }

}
