/* global fetch */
import React, { Component } from 'react';
// import {
//     Link
// }
// from 'react-router-dom'
import { Button, Spin, Switch, Icon, notification } from 'antd'
import { Query, Mutation } from 'react-apollo'
import { getUserServices } from 'queries'
import { updateUserServices } from 'mutations'
import gql from "graphql-tag";
import moment from 'moment'

export default class Sync extends Component {
    state = {
        syncBox: false

    }

    onChange(checked, service, UpdateUserServices) {
        const url = 'https://api.tlige.eu/v1/' + service + '/init?uid=' + this.props.authData.attributes['custom:orgId']
        let _state = { ...this.state }


        if (checked) {
            _state[service] = true
            var win = window.open(url, '_blank');
            win.focus();

        }
        else {
            _state[service] = false
            let input = {
                userId: this.props.authData.attributes['custom:orgId']
            }
            input[service] = false
            UpdateUserServices({ variables: { input } })
        }
        this.setState(_state)
    }

    force(service) {
        let _state = { ...this.state }
        _state[service] = true
        this.setState(_state)

        fetch('https://api.tlige.eu/v1/' + service + '/force/uid=' + this.props.authData.attributes['custom:orgId'])
            .then(response => response.json())
            .then(data => this.setState({ data, box: false, onedrive: false, gdrive: false, dropbox: false }));
    }
    render() {
        return (
            <Query 
                query={gql(getUserServices)}
                variables = { { userId: this.props.authData.attributes['custom:orgId'] } }
            >
            {({ loading, data }) => {
                if (loading){
                        const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
                        return <Spin indicator={antIcon} className='spinner' />
                } 
                const {dropbox, gdrive, box, onedrive} = data.getUserServices
                return (
                    <Mutation
                        mutation={ gql(updateUserServices) }
                        update={(cache, data) => {
                        
                          let newData = { ...data.updateUserServices }
                          const userId = this.props.authData.attributes['custom:orgId']
                          
                          cache.writeQuery({
                              query: gql(getUserServices),
                              variables: { userId },
                              data: newData
                          });
                          notification['success']({
                              duration: 2,
                              message: 'Synchronizacja został włączona',
                              description: 'Data operacji: ' + moment().format('YYYY-MM-DD hh:mm'),
                          });
                        }}
                    >
                    {(UpdateUserServices) => {

                        return(
                                <div style={{ padding: 20}}>
                                    <Switch defaultChecked={box} onChange={(c)=>this.onChange(c, 'box', UpdateUserServices)} />
                                        <span className='sync__label'>BOX</span>    
                                    {false && box && <Button icon="sync" loading={this.state.box} onClick={()=>this.force('box')}>Synchronizuj</Button>}
                                    <br/><br/>
                                    <Switch defaultChecked={onedrive} onChange={(c)=>this.onChange(c, 'onedrive', UpdateUserServices)} /> 
                                        <span className='sync__label'>Onedrive </span>
                                    {false && onedrive && <Button icon="sync" loading={this.state.onedrive} onClick={()=>this.force('box')}>Synchronizuj</Button>}
                                    <br/><br/>
                                    <Switch defaultChecked={dropbox} onChange={(c)=>this.onChange(c, 'dropbox', UpdateUserServices)} /> 
                                        <span className='sync__label'>Dropbox </span>
                                    {false && dropbox && <Button icon="sync" loading={this.state.dropbox} onClick={()=>this.force('box')}>Synchronizuj</Button>}
                                    <br/><br/>
                                    <Switch defaultChecked={gdrive} onChange={(c)=>this.onChange(c, 'gdrive', UpdateUserServices)} /> 
                                        <span className='sync__label'>Google Drive </span>
                                    {false && gdrive && <Button icon="sync" loading={this.state.gdrive} onClick={()=>this.force('box')}>Synchronizuj</Button>}
                                    <br/><br/>
                                </div>
                                );
                            }}
                      </Mutation>
                )
            }}  
            </Query>
        )
    }
}
