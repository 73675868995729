import React, { Component } from 'react';

export default class FileViewer extends Component {

    render() {
        const isImage = this.props.type.split("/")[0] === 'image'

        const url = encodeURIComponent(this.props.url)
        if (url === null) return null

        return (
            <div height={(window.innerHeight) + 'px'}>
            { isImage ? <img alt='imageViewer' src={decodeURIComponent(url)} style={{ 'max-width':'800px' }} />
               : <iframe title="fileViewer" src={'https://docs.google.com/viewer?url='+url+ "&embedded=true"} width='800px' height={(window.innerHeight) + 'px'} frameBorder='0'></iframe>
            }</div>
        )
    }
}
