import React, { Component, Fragment } from 'react';
import { Query, Mutation } from 'react-apollo'
import { listFiles, getFileInfo, getChain } from 'queries'
import { deleteFiles } from 'mutations'
import { Spin, Icon, Button, Avatar, Modal, Drawer, notification, Input, List, Popconfirm } from 'antd';
import { Storage } from 'aws-amplify';
import gql from "graphql-tag";
import moment from 'moment'
import FileViewer from 'FileViewer'
// import Sync from 'Sync'
// import ChainLog from 'ChainLog'

const TextArea = Input.TextArea
const IconText = ({ type, text }) => (
    <span>
    <Icon type={type} style={{ marginRight: 8 }} />
    {text}
    </span>
);

export default class FileList extends Component {
    state = {
        viewer: false,
        viewerUrl: '',
        visible: false,
        modalItem: null
    }

    showModal = (modalItem) => {
        this.setState({
            visible: true,
            modalItem
        });
    }

    handleOk = (e) => {
        console.log(e);
        this.setState({
            visible: false,
            modalItem: null,
        });
    }

    handleCancel = (e) => {
        console.log(e);
        this.setState({
            visible: false,
            modalItem: null

        });
    }


    showDrawer = (item) => {
        Storage.get(item.key)
            .then(url => {
                this.setState({
                    viewer: true,
                    viewerUrl: url,
                    viewerFt: item.type
                });
            })
    }

    hideDrawer = () => {
        this.setState({
            viewer: false,
            viewerUrl: null,
            viewerItem: null
        });
    }
    reloadDrawer = (url) => {
        this.setState({
            viewerUrl: null
        });
        setTimeout(() => {
            this.setState({
                viewerUrl: url
            });
        }, 1000)
    }

    handleDelete = () => {
        alert(1)
    }

    getIcon = (type) => {
        if (type === 'application/pdf') return 'file-pdf'
        else if (type && type.indexOf('image') >= 0) return 'picture'
        else if (type === 'office/word') return 'file-word'
        else if (type === 'office/excel') return 'file-excel'
        else return 'file'

    }

    onLoadMore = (fetchMore, nextToken) => {
        fetchMore({
            variables: {
                nextToken
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!prev.listFiles.nextToken) return prev;
                
                return Object.assign({}, prev, {
                    ...prev,
                    listFiles: { ...prev.listFiles, nextToken: fetchMoreResult.listFiles.nextToken, items: [...prev.listFiles.items, ...fetchMoreResult.listFiles.items] }
                });
            }
        })
    }

    downloadFile = (key) => {
        const _this = this
        Storage.get(key)
            .then(url => {
                _this.linkRef.href = url
                _this.linkRef.click()
            })
    }
    
    deleteFile = (id, key, df) => {

        df({
            refetchQueries: [{
                query: gql(getChain),
                variables: { id: this.props.username }
            }],
            variables: { input: { id } }
        })
    }

    render() {
        const { refetch, project } = this.props
        const { viewerUrl, viewerKey } = this.state
        let filter = {}
        if (project && project !== 'all') filter = { projectId: { eq: project } }

        return (
            <Fragment>
                    <Mutation 
                            mutation={ gql(deleteFiles) }
                            update={(cache, data) => {
                              const filter={}
                              const ce= cache.readQuery({ query: gql(listFiles), variables: { filter }  });
                              
                            //   const { items } = ce.listProceedings
                              let newData = {...ce}
                              newData.listFiles.items = newData.listFiles.items.filter(p => p.id !== data.data.deleteFiles.id)
                              
                            Storage.remove(data.data.deleteFiles.key)
                                .then()
                                .catch();
                              
                              
                              cache.writeQuery({
                                    query: gql(listFiles),
                                    data: newData
                              });
                              notification['success']({
                                  duration: 2,
                                  message: 'Plik został usunięty',
                                  description: 'Data operacji: ' + moment().format('YYYY-MM-DD hh:mm'),
                              });
                        }}>
                        {(deleteFiles, { data }) => (
                        <Query
                            query={ gql(listFiles) }
                            variables = {{ filter }}
                            fetchPolicy={refetch ? 'cache-and-network': 'cache-first'}
                        >
                        {({ loading, data, fetchMore }) => {
                                if (loading) {
                                    const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
                                    return <Spin indicator={antIcon} className='spinner' />
                                
                                } 
                                const loadMore =(
                                  <div style={{
                                    textAlign: 'center', marginTop: 12, height: 32, lineHeight: '32px',
                                  }}
                                  >
                                    <Button onClick={()=>this.onLoadMore(fetchMore, data.listFiles.nextToken)}>loading more</Button>
                                  </div>
                                ) 
                                return (
                            <List
                            itemLayout="horizontal"
                            className="proc_fileList"
                            dataSource={data.listFiles.items}
                            loadMore={data.listFiles.nextToken && loadMore}
                            renderItem={item => (
                              <List.Item
                                actions={[
                                     
                                    <Icon type="info-circle" onClick={()=>this.showModal(item)} />,
                                    <Icon type="search" onClick={()=>this.showDrawer(item)} />,
                                    <span onClick={()=>this.downloadFile(item.key)}><IconText type="cloud-download" /></span>,  
                                    <Popconfirm 
                                        title="Czy na pewno usunąć? Operacja jest nieodwracalna." 
                                        onConfirm={()=>{this.deleteFile(item.id, item.key, deleteFiles)}} 
                                        okText="Tak" cancelText="Nie"
                                        icon={<Icon type="exclamation-circle" style={{ color: 'red' }} />}
                                        ><Icon type="delete" /></Popconfirm>
                                        ]}
                                style={{paddingLeft: 5}}
                              >
                                <List.Item.Meta
                                  
                                  avatar = { <Avatar icon={this.getIcon(item.type)} /> }
                                  title={<span onClick={()=>this.showDrawer(item)}>{item.name}</span>}
                                  description={item.comment?item.comment:null }
                                />
                              </List.Item>
                            )}
                          />
                        
                    );
            }}
          </Query>
        
            )}
        </Mutation>
         <Drawer
          title="Podgląd pliku"
          closable={true}
          width={800}
          onClose={this.hideDrawer}
          visible={this.state.viewer}
          className='file-viewer'
        >
          <FileViewer url={this.state.viewerUrl} type={this.state.viewerFt}/>
          
          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
            }}
          >
            <Button onClick={()=>this.downloadFile(viewerKey)}><IconText type="cloud-download" /> Pobierz</Button>
            <Button style={{marginLeft:5}} onClick={()=>this.reloadDrawer(viewerUrl)}><IconText type="reload" /> Odśwież</Button>
            <Button onClick={this.hideDrawer} style={{ marginRight: 8, float: 'right' }}>
              Zamknij
            </Button>
            
          </div>
        </Drawer>
        
        <Modal
          title="Metryka pliku"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
        { this.state.modalItem &&
        <div className='fileInfo' style={{ height: 500 }}>
        <Query 
            query={ gql(getFileInfo) }
            variables={{ key: 'public/'+this.state.modalItem.key }}
            fetch-police={'cache-first'}
            >{({loading, data}) => {
                if(loading) return <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}/>
             
            return(
               <Fragment>
                <div> Dane podstawowe </div><br/>
                <span className='label'>Nazwa:</span> <span>{this.state.modalItem.name}</span><br/>
                <span className='label'>Rozmiar:</span> <span>{this.state.modalItem.size+ "KB"}</span><br/>
                <br/><br/>
                <div> Plik zabezpieczony <Icon type="lock" /> </div><br/>
                <span className='label'>Podpis (PKCS1_v1_5):</span> <TextArea disabled={true}>{data.getFileInfo.sign}</TextArea><br/>
                <span className='label'>Klucz publiczny:</span> <TextArea disabled={true}>AAAAB3NzaC1yc2EAAAADAQABAAACAQDGs2FRxdmf81nWE+bUOZOsZMD6UBX6BYjSBu3FSfNrozUaZLx5ru/0svnaHNoYojHLZd/Wmme/Okc+VGanGT+5Qw4XwmbOJ94Co1yBN2NDPONWWi6gMwBxP6liTESU5J87d4HNdCOu8k87i82R5ITsAwzH4p/xpo9fuUAK/nnMTwK4ei5pCxCK0GW3y9TSEl06zoF+Af47ZYy3R2iyy8nZFja8MTUORg/VtSNh05odwJ33/ZO+QQFvkuK8nElq+lZeiwfbeTjJ8XqUWyrdRsSOPeFxf0aOacq2/QEHqaMyuDWHr4sbHBdIR8FPpQHn8paNmfR5n5g30k0WHPN10Sh16OM1O8yy23bsM3lqKhiT53LAHEYxUGsyCKDx2n1yaon4rsWkAI+rItuJFyfUeXmP/cCsUg0mbZpwtnTG1wHXIKKUZx9Romg4Gfr82FP89gyK8GXss4aayed/Eg98pGu8cNaThRmIB101iAIUduTM/P7zrj5SWncr0aaiKA8gVMXx2uGl6FrR104p79JvyZ4UMypTjNQLvwkgHtoFA4F7mQeBruaTbS7qVjB5UAry6dgKdkyzZMkgSnWYj3delOAq/XXhqUE/J9VU97fR9+BMg+ukPja3/suxDp3wu9eWEOXhC5xJ1n3wMka+jTDsXGkylKy6lW0gyloXYGarfC4IzQ==
</TextArea><br/>
                <span className='label'>Sygnatura (SHA256):</span> <TextArea disabled={true}>{data.getFileInfo.hash}</TextArea><br/>
                <span className='label'>Znacznik czasu:</span> <TextArea disabled={true}>{data.getFileInfo.timestamp}</TextArea>
                <br/><br/>
                <div> Synchronizacja <Icon type="cloud" /></div><br/>
                <Icon type={data.getFileInfo.dropbox ? "check-circle": "close-circle"} /><span className='label'>Dropbox:</span> <br/><br/>
                 <Icon type={data.getFileInfo.gdrive ? "check-circle": "close-circle"} /><span className='label'>Google Drive:</span><br/><br/>
                 <Icon type={data.getFileInfo.onedrive ? "check-circle": "close-circle"} /><span className='label'>Onedrive:</span><br/><br/>
                 <Icon type={data.getFileInfo.box ? "check-circle": "close-circle"} /><span className='label'>Box</span> <br/><br/>
              </Fragment>
            )
            }}
          </Query>
          </div>
        }
        </Modal>
        { /* eslint-disable-next-line */ }
        <a href="" style={{"dispaly":"none"}} download ref={el => (this.linkRef = el)} ></a>
        </Fragment>
        )

    }

}
