import React, { Component, Fragment } from 'react';
import { Icon, Timeline, Row, Col, Button } from 'antd';

import moment from 'moment'

export default class ChainLog extends Component {
    state = { visible: false };

    shouldComponentUpdate(nextProps, nextState) {
        console.log(this.props, nextProps)
        if (this.props.chain.blocks.length === nextProps.chain.blocks.length)
            return false
        return true;
    }

    showDrawer = () => {
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
        });
    };

    onExpandableClick = (e) => (
        e.target.classList.toggle("expanded")
    )

    render() {
        console.log("Render")
        const logs = [
            "Dodanie postępowania",             "Aktualizacja postępowania",             "Dodanie pliku",             "Usunięcie pliku"
        ]
        const logsColor = [
            "green",             "blue",             "green",             "red"
        ]
        const { chain } = this.props
        return (
            <Row style={{ padding: "0px 20px" }}>
                <Col span={24}><Button type="primary" onClick={() => this.props.refetch()}><Icon type="reload" /> Odśwież</Button></Col>
                <br/><br/><br/>
                <Col span={24}>
                  <Timeline pending={this.props.pending} reverse="true" >
                    {chain.blocks.map(
                        (b,i)=> {
                            let log = JSON.parse(b.messages['0'].data)
                           
                            return(
                                <Timeline.Item color={logsColor[log.log]} key={i}>
                                    <div className="expandable">
                                        { logs[log.log] } - <span className='time'>{ moment.unix(parseInt(b.timestamp)).format("YYYY-MM-DD hh:mm:ss") } </span>
                                        <div className="details"> 
                                            { log.target }<br/>
                                            { this.props.exFields && this.props.exFields.map((f,j)=>(
                                                log[f.value] && <Fragment key={j}>{f.label}&nbsp;{log[f.value]}<br/></Fragment>
                                            ))}
                                            <Icon type="lock" />&nbsp;{ b.hash }<br/>
                                            <Icon type="user" />&nbsp;{ log.user } ({ log.email })<br/><br/>
                                        </div>
                                    </div>
                                </Timeline.Item>
                        )}
                    )
                    }
                  </Timeline>
                  </Col>
            </Row>


        )
    }
}

// <Fragment>
//                         <Button type="primary" onClick={this.showDrawer}>
//                           Open
//                         </Button>
//                         <Drawer
//                           title="Log - Blockchain"
//                           placement="right"
//                           closable={false}
//                           onClose={this.onClose}
//                           visible={this.state.visible}
//                           width="600"
//                         >
//                           <Timeline pending={this.props.pending} reverse="true" >
//                             {chain.blocks.map(
//                                 (b,i)=> {
//                                     let log = JSON.parse(b.messages['0'].data)
//                                     console.log(log)
//                                     return(
//                                         <Timeline.Item color={logsColor[log.log]} key={i}>
//                                             <div className="expandable" onClick={this.onExpandableClick}>
//                                                 { logs[log.log] } - <span class='time'>{ moment.unix(parseInt(b.timestamp)).format("YYYY-MM-DD hh:mm:ss") } <Icon type="clock-circle" /></span>
//                                                 <div className="details"> 
//                                                     { log.target }<br/>
//                                                     { log.user } ({ log.email })<br/>
//                                                     <Icon type="lock" />&nbsp; { b.hash }<br/><br/>
//                                                 </div>
//                                             </div>
//                                         </Timeline.Item>
//                                 )}
//                             )
//                             }
//                           </Timeline>

//                         </Drawer>
//                     </Fragment>
