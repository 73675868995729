import React, { Component, Fragment } from 'react';
// import logo from './logo.svg';
import './App.css';
import { Auth, I18n } from 'aws-amplify';
// import { withAuthenticator } from 'aws-amplify-react';
import { ConfirmSignIn, ForgotPassword, VerifyContact, RequireNewPassword, withAuthenticator } from 'aws-amplify-react';
import AppContext from 'Context'
import { Query } from 'react-apollo'
import { getOrganization } from 'queries'
import gql from "graphql-tag";
import {
  BrowserRouter as Router,
  Route,
  Link
}
from 'react-router-dom'


// import Home from 'Home'
import SignUp from 'CustomSignUp';
import SignIn from 'CustomSignIn';
import ConfirmSignUp from 'CustomConfirmSignUp';
import Files from 'Files'
// import Mails from 'Mails'
import Proceeding from 'Proceeding'
import Proceedings from 'Proceedings'
import Calendar from 'Calendar'
import Settings from 'Settings'


import {
  Layout,
  Menu,
  Icon,
  Spin
}
from 'antd';

const {
  Header,
  Content,
  // Footer,
} = Layout;
// const SubMenu = Menu.SubMenu;


const authScreenLabels = {
  pl: {
    'Send Code': 'Prześlij kod',
    'Resend Code': 'Prześlij kod ponownie',
    'New Password': 'Nowe hasło',
    'Code': 'Kod',
    'Enter your username': 'Nr telefonu (+48...)',
    'Username': 'Numer telefonu',
    'Reset your password': 'Resetowanie hasła',
    'Back to Sign In': 'Wróc do logowania',
    'Submit': 'Zatwierdź',
    'Confirmation Code': 'Kod potwierdzający',
    'Lost your code? ': 'Nir otrzymałeś kodu? ',
    'Confirm Sign Up': 'Potwierdź rejestrację',
    'Enter your code': 'Wprowadź kod',
    'Confirm': 'Potwierdź',
    'Username/client id combination not found.': 'Nie ma takiego użytkownika. Upewnij się że wprowadziłeś poprawny format np. +48601123456',
    'Invalid verification code provided, please try again.': 'Niepoprawny kod weryfikacyjny, spróbuj ponownie',
    'Change Password': 'Zmień hasło',
    'Change': 'Zmień',
    'User does not exist': "Zły telefon lub hasło",
    'An account with the given phone_number already exists.': "Konto dla tego numeru telefonu już istnieje"
  }
};

I18n.setLanguage('pl');
I18n.putVocabularies(authScreenLabels);

// const AppContext = React.createContext();

const MyTheme = {
  formSection: { 'border': '1px solid #f0f2f5', boxShadow: 'none', borderRadius: '0px' },
  button: {
    color: '#fff',
    background: '#40a9ff',
    borderColor: '#40a9ff',
    fontSize: '14px',
    letterSpacing: 0,
    textTransform: 'none',
    padding: '0 15px',
    borderRadius: '4px',
    height: '32px'
  },
  a: {
    color: 'rgb(0, 123, 255)',
    cursor: 'pointer',
    textDecoration: 'none'
  },
  input: {
    padding: '4px 11px',
    width: '100%',
    height: '32px',
    fontSize: '14px',
    lineHeight: '1.5'
  }
}


class App extends Component {
  constructor(props) {
    super(props);
    this.state = { authData: props.authData, collapsed: true, loggingOut: false, isLoading: false, style: null }
  }

  async componentDidMount() {
    // this.setState({ isLoading: true });
    // console.log(this.state.authData)

    if (!this.state.authData.attributes) {
      let authData = await Auth.currentAuthenticatedUser();


      this.setState({
        authData
      });

    }

  }

  onCollapse = (collapsed) => {
    console.log(collapsed);
    this.setState({ collapsed });
  }

  handleSignOut = () => {
    this.setState({ loggingOut: true })
    Auth.signOut()
      .then(data => window.location.href = "https://tlige.eu")
      .catch(err => console.log(err));
  }

  setStyle = () => {
    if (this.state.style === null) {
      this.setState({ style: 'wcag' })
    }
    else this.setState({ style: null })
  }

  render() {
    const { authData, loggingOut } = this.state


    if (!authData) return null

    if (!authData.attributes) {
      const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
      return <Spin indicator={antIcon} className='spinner' />
    }

    return (
      <div className="App">
      <Router>
        <AppContext.Provider value={authData}>
         <Query
                     query={ gql(getOrganization) }
                     variables = {{ userId: authData.username  }}
                     fetchPolicy={'cache-first'}
                 >
                 {({ loading, data}) => {
        
         if (loading){
                const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
                return <Spin indicator={antIcon} className='spinner' />
            }
            
        const authDataOrg={
          ...authData,
          org: {...data.getOrganization }
        }
        let perms = {}
        authDataOrg.org.perms && authDataOrg.org.perms.map(val=>perms[val]=true)
        
        let path = window.location.pathname
        try{
          path = path.split("/")[1]
          if(path==='postepowanie')
            path='projekty'
        }catch(e){
          path = 'projekty'
        }
        return (
          <Layout className={"layout "+ this.state.style} >
          <Header style={{ position: 'fixed', zIndex: 2, width: '100%', height: '80px' }}>
            <div className="logo">
              <img src="https://tlige.eu/assets/images/logo.png" alt="logo" className="img-responsive" />
            </div>
            <Menu
              mode="horizontal"
              defaultSelectedKeys={[path]}
              style={{ lineHeight: '80px' }}
            >
                  <Menu.Item key='projekty'>
                  <Link to='/'>
                    <Icon type="project" />
                    <span>Postępowania</span>
                   </Link>
                  </Menu.Item>
                  { !perms.hide_files && 
                  <Menu.Item key="pliki">
                    <Link to='/pliki' >
                      <Icon type="folder-open" />
                      <span>Pliki</span>
                    </Link>
                  </Menu.Item>
                  }{ !perms.hide_cal && 
                    <Menu.Item key="kalendarz">
                    <Link to='/kalendarz' >
                      <Icon type="calendar" />
                      <span>Kalendarz</span>
                     </Link>
                    </Menu.Item>
                  }{ !perms.hide_organization && 
                  <Menu.Item key="ustawienia">
                  <Link to='/ustawienia' >
                    <Icon type="setting" />
                    <span>Ustawienia</span>
                   </Link>
                  </Menu.Item>
                  } 
                  <Menu.SubMenu style={{float: 'right'}} title={<Fragment><Icon type="user" /> &nbsp;<span  style={{ fontWeight: 'bold'}}>{ authData.attributes.name + ' '+authData.attributes.family_name }</span></Fragment>}>
                     
                      <Menu.Item key="21" onClick={this.handleSignOut} > <Icon type={ loggingOut ? 'loading':'logout'} theme="outlined" /> Wyloguj </Menu.Item>
                       <Menu.Item key="22" onClick={this.setStyle} > {this.state.style===null ? <Icon type="eye-invisible" />: <Icon type="eye" /> } Kontrast </Menu.Item>
              
                </Menu.SubMenu>
            </Menu>
          </Header>
          <Content style={{ padding: '0 0px', minHeight:'85vh', marginTop: 80 }}>
            
            <div style={{ background: '#fff', minHeight: '100%' }}>
              <Route path="/" exact render={(props) => <Proceedings {...props} authData={authDataOrg} />} />
              { !perms.hide_files &&  <Route path="/pliki" render={(props) => <Files authData={authDataOrg} />} /> }
              { !perms.hide_projects && 
              <Fragment><Route path="/postepowanie/:value" render={(props) => <Proceeding {...props} authData={authDataOrg} />} />
              </Fragment>
              }
              { !perms.hide_cal && <Route path="/kalendarz" render={(props) => <Calendar authData={authDataOrg} />} /> }
              { !perms.hide_organization && <Route path="/ustawienia" render={(props) => <Settings authData={authDataOrg} />} /> }
            </div>
          </Content>
          
        </Layout>
      )}}</Query>
      </AppContext.Provider>
      </Router>
    </div>


    );
  }
}

export default withAuthenticator(App, false, [
  <SignIn/>,
  <ConfirmSignIn/>,
  <VerifyContact/>,
  <SignUp/>,
  <ConfirmSignUp/>,
  <ForgotPassword/>,
  <RequireNewPassword/>
], null, MyTheme);

// <Footer style={{ textAlign: 'center' }}>
//             Tlige. Wszystkie prawa zastrzeżone.
//           </Footer>
