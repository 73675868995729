// WARNING: DO NOT EDIT. This file is Auto-Generated by AWS Mobile Hub. It will be overwritten.

// Copyright 2017-2018 Amazon.com, Inc. or its affiliates (Amazon). All Rights Reserved.
// Code generated by AWS Mobile Hub. Amazon gives unlimited permission to
// copy, distribute and modify it.

// AWS Mobile Hub Project Constants
const awsmobile = {
    'aws_app_analytics': 'enable',
    'aws_cognito_identity_pool_id': 'eu-west-1:365431b3-ca18-41d8-a783-ee8abd591c81',
    'aws_cognito_region': 'eu-west-1',
    'aws_content_delivery': 'enable',
    'aws_content_delivery_bucket': 'min-hosting-mobilehub-1807454585',
    'aws_content_delivery_bucket_region': 'eu-west-1',
    'aws_content_delivery_cloudfront': 'enable',
    'aws_content_delivery_cloudfront_domain': 'd2e1zo4kzpd7ni.cloudfront.net',
    'aws_mobile_analytics_app_id': '10624c2d247e4f6bb2d67799bbff39b9',
    'aws_mobile_analytics_app_region': 'us-east-1',
    'aws_project_id': '30d7583d-22a0-4237-bd73-d9e31ba9b622',
    'aws_project_name': 'MIN_',
    'aws_project_region': 'eu-west-1',
    'aws_resource_name_prefix': 'min-mobilehub-1807454585',
    'aws_sign_in_enabled': 'enable',
    'aws_user_files': 'enable',
    'aws_user_files_s3_bucket': 'min-userfiles-mobilehub-1807454585',
    'aws_user_files_s3_bucket_region': 'eu-west-1',
    'aws_user_pools': 'enable',
    'aws_user_pools_id': 'eu-west-1_pM4DWVFGF',
    'aws_user_pools_web_client_id': '6l9qf7fcckpu09ntqm9frgekdq',
    "aws_appsync_graphqlEndpoint": "https://e5mhbfvadjcnlg6m7hjyl5ttly.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "null",
}

export default awsmobile;
