import React from 'react'

import { ConfirmSignUp, Link } from 'aws-amplify-react';
import { Form, Input, Button } from 'antd';
import { Auth } from 'aws-amplify'

const FormItem = Form.Item;
// const Option = Select.Option;

// const RadioGroup = Radio.Group;


class CustomConfirmSignUp extends ConfirmSignUp {
  state = {
    confirmDirty: false,
    mfa: false
  };

  handleSubmit = async(e) => {
    e.preventDefault();
    const _this = this

    this.setState({ mfa: true })
    this.props.form.validateFields((err, values) => {

      if (!err) {
        // var _this3 = this;

        var { username, code } = values

        // console.log(values)
        Auth.confirmSignUp(username, code)
          .then(function() { _this.setState({ mfa: false }); return _this.changeState('signedUp'); })
          .catch(function(err) { _this.setState({ mfa: false }); return _this.error(err); });

      }
    });
  }


  back = () => {
    window.reload();
  }



  showComponent = () => {
    if (this.props.authState !== 'confirmSignUp')
      return null;

    const username = this.usernameFromAuthData() || this.inputs.username

    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };


    return (
      <Form onSubmit={this.handleSubmit} className="signup-form" method="POST">
            <div className="logo">
              <img src="https://tlige.eu/assets/images/logo.png" alt="logo" className="img-responsive" />
            </div>
            <br/>
              <FormItem
                {...formItemLayout}
                label="Telefon"
              >
                {getFieldDecorator('username', { initialValue : username })(
                  <Input disabled={true}  style={{ width: '100%' }} />
                )}
              </FormItem>
              
              <FormItem
                {...formItemLayout}
                label="Kod weryfikacyjny"
              >
                {getFieldDecorator('code', {
                  rules: [{ required: true, message: 'Podaj kod otrzymany na wskazany nr telefonu' }],
                })(
                  <Input style={{ width: '100%' }} />
                )}
              </FormItem>
              
              
              <FormItem {...tailFormItemLayout}>
                <Button loading = { this.state.mfa } type="primary" htmlType="submit" className="login-form-button">Potwierdź</Button>
                <Button type="primary" className="login-form-button" onClick={this.resend}>Prześlij ponownie</Button><br/>
                <Link  className="login-form-forgot" onClick={ ()=> { this.changeState('signIn')}}>Wróc do logowania</Link>
              </FormItem>
              
              
      </Form>

    )
  }

  render() {
    return this.showComponent()
  }

}



export default Form.create()(CustomConfirmSignUp);
