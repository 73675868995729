import fetch from 'unfetch';
import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';


import App from './App';
import * as serviceWorker from './serviceWorker';
// import 'antd/dist/antd.css';
import 'react-keyed-file-browser/dist/react-keyed-file-browser.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Amplify from 'aws-amplify';
import aws_exports from './aws-exports';
import { LocaleProvider } from 'antd';
import pl_PL from 'antd/lib/locale-provider/pl_PL';
import AWSAppSyncClient from "aws-appsync";
import { Rehydrated } from 'aws-appsync-react';
import { ApolloProvider } from "react-apollo";
import { Auth } from 'aws-amplify'
// eslint-disable-next-line
// import moment from 'moment/min/moment-with-locales';

import moment from 'moment';
// eslint-disable-next-line
import 'moment/locale/pl';
import Moment from 'react-moment';
// import './index.css';
import { InMemoryCache } from 'apollo-cache-inmemory';

Moment.globalMoment = moment;
Moment.globalLocale = 'pl';
Moment.globalFormat = 'YYYY-MM-DD HH:mm';
// eslint-disable-next-line
moment.locale('pl');

const cache = new InMemoryCache({
    dataIdFromObject: object => object.id || null
});


const client = new AWSAppSyncClient({
    url: aws_exports.aws_appsync_graphqlEndpoint,
    region: aws_exports.aws_appsync_region,
    disableOffline: true,
    auth: {
        type: 'AMAZON_COGNITO_USER_POOLS',
        jwtToken: async() => (await Auth.currentSession()).getIdToken().getJwtToken(),
    },
    cache
});
// eslint-disable-next-line
String.prototype.text_truncate = function(length, ending) {
    if (length == null) {
        length = 100;
    }
    if (ending == null) {
        ending = '...';
    }
    if (this.length > length) {
        return this.substring(0, length - ending.length) + ending;
    }
    else {
        return this;
    }
};


Amplify.configure(aws_exports);
// Amplify.Logger.LOG_LEVEL = 'DEBUG'
Amplify.Logger.LOG_LEVEL = 'ERROR'

const ApolloApp = () => (
    <LocaleProvider locale={pl_PL}>
        <ApolloProvider client={client}>
            <Rehydrated>
                <App />
            </Rehydrated>
        </ApolloProvider>
    </LocaleProvider>
);

ReactDOM.render(<ApolloApp />, document.getElementById('root'));
serviceWorker.register();
