import React, { Component, Fragment } from 'react';
// import FileBrowser, { Icons } from 'react-keyed-file-browser'
// import Moment from 'moment'
import { Query } from 'react-apollo'
import { listProceedings, getChain } from 'queries'
// import { createFiles, deleteFiles } from 'mutations'
import { Spin, Icon, Tabs } from 'antd';
// import { Storage } from 'aws-amplify';
// import { S3Image } from 'aws-amplify-react';
import gql from "graphql-tag";
// import moment from 'moment'
// variables={{ filter: { projectId: {eq: '46226491-d200-4acf-a12e-6e03da0c89d3' },  userId: {eq: '46226491-d200-4acf-a12e-6e03da0c89d3' } } }}
// import FileViewer from 'FileViewer'
import Sync from 'Sync'
import ChainLog from 'ChainLog'
import FileList from 'FileList'
import FileAdd from 'FileAdd'



// <ul>{ data && data.listFiles.items.map((file,i)=><div key={i}>{ file.name }</div>) }</ul>


export default class Files extends Component {
    state = {
        project: 'all',
        loading: false,
        activeTab: 1

    }

    setProject = (value) => {
        this.setState({ project: value })
    }
    componentWillUnmount() {
        this._unmounted = true;
    }

    render() {
        const { authData } = this.props
        const { project } = this.state
        return (
            <Tabs defaultActiveKey="1" onChange={i=>this.setState({activeTab: i})}>
        <Tabs.TabPane tab="Repozytorium" key="1">
            <Query 
                query={ gql(listProceedings) }
                fetch-police={'cache-first'}
                >{({loading, data}) => {
                    if(loading)return null
                    const items = [].concat(data.listProceedings.items)
                         
                return(
                <Fragment>  
                    <FileAdd items={items} authData={authData} project='all'/>
                    <FileList project={project} username={authData.username}/>
                </Fragment>
                )
            }}</Query>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Synchronizacja" key="2">
            <Sync authData={this.props.authData} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Blockchain" key="3">
            {this.state.activeTab==="3" &&
                <Query 
                    query={ gql(getChain) }
                    variables={{ id: this.props.authData.org.orgId }}
                >{({loading, data, refetch}) => { 
                    if (loading) return <div style={{ minHeight: '85vh', paddingTop: '10vh'}}><Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}/></div>
                
                    if(data.getChain == null) 
                        return (
                            <div> Repozytorium puste </div>
                        )
                    
                    
                    return (
                        <ChainLog refetch={refetch} chain={JSON.parse(data.getChain.json)} exFields={ [{ label: <Icon type="file-protect" title='sygnatura' />, value: 'hash'}] }/>
                    )
                    }
                    }
                </Query>
            }
        </Tabs.TabPane>
        
  </Tabs>
        )

    }
}
