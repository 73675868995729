import React, { Component, Fragment } from 'react';
import { Mutation } from 'react-apollo'
import { createFiles } from 'mutations'
import { Storage } from 'aws-amplify';
import gql from "graphql-tag";
import { listFiles, getChain } from 'queries'
import { Icon, Upload, Form, Button, Modal, Select, notification, Input } from 'antd';
import moment from 'moment'


class FileAdd extends Component {
    state = {
        uploading: false,
        visible: false
    }


    componentWillUnmount() {
        this._unmounted = true;
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    }

    handleOk = (e, CreateFile) => {
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false, visible: false });
        }, 3000);
        const _this2 = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                CreateFile({
                        refetchQueries: [{
                            query: gql(getChain),
                            variables: { id: this.props.authData.org.orgId }
                        }],
                        variables: {
                            input: { ...values, added: moment() }
                        }
                    }).then(({ data }) => {
                        if (!_this2._unmounted)
                            this.setState({ loading: false, visible: false, newFileObject: null });
                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({ saving: false, newFileObject: null });
                    });
            }
            else { this.setState({ saving: false, newFileObject: null }); }
        });
    }

    handleCancel = (key) => {

        Storage.remove(this.state.newFileObject.key)
            .then()
            .catch();

        this.setState({ visible: false, newFileObject: null });
    }

    handleStartFileUpload = async fileObject => {
        let name = fileObject.file.name.split(".")
        let d = moment().format('YYYY-MM-DD-hhmmss')
        name[0] += "_" + d
        name = name.join(".").replace(" ", "_")

        const res = await Storage.put(this.props.authData.org.orgId + '/' + name, fileObject.file)
        return res;
    }

    handleUpload = (fileObject, CreateFile) => {
        this.setState({ uploading: true })
        this.handleStartFileUpload(fileObject).then(file => {

            Storage.get(file.key)
                .then(url => {
                    const file2 = file.key.split("/")
                    let name = file2[file2.length - 1]
                    name = name.split(".")
                    let ext = ''
                    if (name.length > 1)
                        ext = name.pop()
                    let type = fileObject.file.type
                    if (ext === 'doc' || ext === 'docx' || ext === 'odt')
                        type = 'office/word'
                    else if (ext === 'xml' || ext === 'xmlx')
                        type = 'office / excel'
                    const input = {
                        name: fileObject.file.name.split(".")[0],
                        size: fileObject.file.size ? fileObject.file.size : null,
                        type: type ? type : null,
                        key: file.key
                    }

                    this.setState({
                        visible: true,
                        newFileObject: input,
                        uploading: false
                    })

                })
                .catch(err => console.log(err));
        });

    }



    render() {
        const { authData, items, project } = this.props
        const { newFileObject, uploading } = this.state
        const { getFieldDecorator } = this.props.form;

        // let project = null
        // if (items.length == 1) project = items[0].id

        return (
            <Mutation 
                mutation={ gql(createFiles) }
                update = {
                    (cache, data) => {
                        let filter = {}
                        if (this.props.project !== 'all') filter = { projectId: { eq: project } }
                
                        const ce = cache.readQuery({ query: gql(listFiles), variables: { filter } });
                        let newData = { ...ce }
                        newData.listFiles.items.unshift(data.data.createFiles)
                
                        cache.writeQuery({
                            query: gql(listFiles),
                            data: newData
                        });
                
                        notification['success']({
                            duration: 2,
                            message: 'Plik został dodany',
                            description: 'Data dodania: ' + moment().format('YYYY-MM-DD hh:mm'),
                        });
                    }
                }
            >
            {(CreateFile, { data, loading}) => (
                <Fragment>
                    <Upload  
                                                                className='proc_fileAdd'
                                                                customRequest = {(fileObject)=>this.handleUpload(fileObject, CreateFile, authData) }
                                                                showUploadList = { false }>
                                                                <Button loading={uploading} disabled={uploading} type="primary" >
                                                                  { uploading ? <Fragment> Dodawanie pliku...</Fragment> : <Fragment><Icon type="upload" /> Dodaj plik</Fragment>} 
                                                                </Button>
                                                            </Upload>
                    <Modal
                      visible={this.state.visible}
                      title="Dodaj plik"
                      footer={[
                        <Button key="back" onClick={this.handleCancel}>Anuluj</Button>,
                        <Button key="submit" type="primary" loading={loading} onClick={(e)=>this.handleOk(e,CreateFile)}>
                          Dodaj plik
                        </Button>,
                      ]}
                        >
                        <Form>
                            <Form.Item label="Nazwa">
                              {getFieldDecorator('name', {
                                initialValue: newFileObject ? newFileObject.name: null,
                                rules: [{
                                   required: true,
                                   message: "Pole jest wymagane"
                                 }]
                              })(
                                <Input />
                              )}
                            </Form.Item>
                            <Form.Item label="Komentarz">
                              {getFieldDecorator('comment', {
                                  initialValue: newFileObject ? newFileObject.comment: null
                              })(
                                <Input.TextArea />
                              )}
                            </Form.Item>
                            
                            
                            {project==='all' ? 
                                    <Form.Item
                                          label="Postępowanie"
                                        >
                                      {getFieldDecorator('projectId', {
                                        initialValue: newFileObject ? newFileObject.projectId: null,
                                        rules: [{
                                           required: true,
                                           message: "Pole jest wymagane"
                                         }]
                                      })(
                                        <Select
                                            showSearch
                                            style={{ width: 200 }}
                                            placeholder="Wybierz postępowanie">
                                            { items.map( i=> (<Select.Option key={i.id} value={i.id}>{i.title}</Select.Option>) )}
                                         
                                        </Select>
                                        
                                      ) }</Form.Item>:
                                      <Fragment>
                                      {
                                      getFieldDecorator('projectId', {
                                        initialValue: project,
                                        rules: [{
                                           required: true,
                                           message: "Pole jest wymagane"
                                         }]
                                      })(
                                        <Input type="hidden"/>
                                      )}
                                      </Fragment>
                                      
                            }
                            
                            <Form.Item
                              label="Typ"
                            >
                              {getFieldDecorator('type', {
                                  initialValue: newFileObject ? newFileObject.type: null
                              })(
                                <Input disabled={true} />
                              )}
                            </Form.Item>
                            <Form.Item>
                              {getFieldDecorator('size', {
                                  initialValue: newFileObject ? newFileObject.size: null
                              })(
                                <Input type='hidden' />
                              )}
                            </Form.Item>
                            
                             <Form.Item>
                              {getFieldDecorator('key', {
                                  initialValue: newFileObject ? newFileObject.key: null
                              })(
                                <Input type='hidden' />
                              )}
                            </Form.Item>
                        </Form>
                    </Modal>
                </Fragment>
                     
                )}
            </Mutation>
        )
    }
}


export default Form.create({})(FileAdd);
