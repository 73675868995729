import React, { Component } from 'react';
import BigCalendar from 'react-big-calendar'
import moment from 'moment'
// import 'moment/locale/pl'
import html2pdf from 'html2pdf.js'
import { Button, Drawer, Spin, Icon, Select } from 'antd'
import { listProceedings } from 'queries'
import { Query } from 'react-apollo'
import gql from "graphql-tag";


export default class Calendar extends Component {
    state = { visible: false, project: null, projectName: 'Wszystkie projekty' };

    showDrawer = () => {
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
        });
    };

    printAgenda = () => {
        var element = document.getElementsByClassName('ant-drawer-body')[0]

        let opt = {
            margin: 0.5,
            filename: 'agenda-' + moment().format('YYYY-MM-DD-hhmm'),
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };

        html2pdf().set(opt).from(element).save();
    }
    getEvents = (items) => {

        const { project } = this.state
        console.log(project)

        let mapper = {
            field25Date: "Ustalenie wartości zamówienia",
            field42Data: "Powołanie komisji przetargowej",
            field43Data: "Powołanie zespołu (art. 20a Prawo zamówień publicznych)",
            field51Date: "Ogłoszenie informacji o zamówieniu",
            field52Date: "Zamieszczenie ogłoszenia na stronie www",
            field61Date: "Zamieszczenie ogłoszenia o zamówieniu",
            field63From: "Zamieszczenie ogłoszenie o zamówienie na stronie www",
            field63To: "Zdjęcie ogłoszenia o zamówienie ze strony www",
            field67Date: "Zamieszczenie ogłoszenia o zmianie",
            field7From: "Udostępnienie SWIZ na stronie www",
            field7To: "Zdjęcie SWIZ ze strony www",
            field103: "Ostateczny termin składania ofert",
            field103ExpandDate: "Data przedłużenia terminu składania ofert",
            field111Date: "Otwarcie ofert",
            field201Date: "Zakończenie prac komisji przetargowej/osób",
            field202Date: "Zatwierdzenie prac komisji przetargowej/osób",
            field21Date: "Przekazania zawadomienia o wyborze/unieważnieniu",
            field2311Date: "Data wniesienia odwołania",
            field251Date: "Ponowne zakończenie prac komisji przetargowej/osób",
            field252Date: "Ponowne zatwierdzenie prac komisji przetargowej/osób",
            field261Date: "Zawarcie umowy ramowej",
            field262Date: "Publikacja ogłoszenia o udzieleniu zamówienia",
            field30Date: "Zatwierdzenie protokołu",

        }
        let events = {
            all: [],
            min: moment(),
            max: moment()
        }
        let i = 0,
            d = null
        items.map((item) => {
            if (item.id === project || project === null) {
                Object.keys(item).forEach((it, j) => {
                    if (mapper[it] && item[it]) {
                        d = moment(item[it])
                        events.all.push({
                            id: i,
                            title: mapper[it],
                            start: d,
                            end: d,
                            allDay: true
                        })
                        i++
                        if (d < events.min)
                            events.min = d
                        else if (d > events.max)
                            events.max = d
                    }
                })
            }
            return item
        })
        return events
    }
    setProject = (value) => {
        value = value.split("|||")
        value[0] === 'all' ? this.setState({ project: null, projectName: 'Wszystkie projekty' }) : this.setState({ project: parseInt(value[0]), projectName: value[1] })
    }

    render() {
        // const { authData } = this.props
        const localizer = BigCalendar.momentLocalizer(moment)
        const messages = {
            date: 'Data',
            time: 'Czas',
            event: 'Wydarzenie',
            allDay: 'Cały dzień',
            week: 'Tydzień',
            work_week: 'Tydzień',
            day: 'Dzień',
            month: 'Miesiąć',
            previous: 'Poprzedni',
            next: 'Następny',
            yesterday: 'Wczoraj',
            tomorrow: 'Jutro',
            today: 'Dzisiaj',
            agenda: 'Agenda',
            noEventsInRange: 'Brak wydarzeń',
            showMore: function showMore(total) {
                return "+" + total + " więcej";
            }
        };




        // let vars = null
        // if (this.state.project) vars = { filter: { id: { eq: this.state.project } } }
        // console.log(vars)

        return (
            <Query 
            query={ gql(listProceedings) }
         
            fetch-police={'cache-first'}
            >{({loading, data}) => {
                if(loading) return <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}/>
                
                const items = [].concat(data.listProceedings.items)
                let events = this.getEvents(items)
                
                return (
            <div style={{ padding: "0 20px 0 20px", height: (window.innerHeight-240) + 'px'}}>
                <br/>
                <Button type="primary" onClick={this.showDrawer}>
                    Pokaż agendę
                </Button>
            
                <Select
                    showSearch
                    style={{ width: 200, marginLeft: 10 }}
                    placeholder="Wybierz postępowanie"
                    onChange={this.setProject}
                    
                >
                    <Select.Option key={0} value='all'>Wszystkie</Select.Option>
                    {items.map(i=><Select.Option key={i.id} value={i.id+'|||'+i.title}>{i.title}</Select.Option>)}
                </Select>
                <br/>
           
            
            
            <BigCalendar
                events={events.all}
                step={60}
                views={["month", "work_week", "day"]}
                showMultiDayTimes
                defaultDate={new Date(Date.now())}
                localizer={localizer}
                culture="pl"
                messages={messages}
                // eventPropGetter={event => {
                //     // const user = this.state.listUsers.find(user => user.id === event.user_id); 
                //     const backgroundColor = event.id%2===0 ? '#000' : "red"; return { style: { backgroundColor } }; 
                // }}
            />
                <Drawer
                  title={"Agenda - " + this.state.projectName.text_truncate() }
                  placement="right"
                  closable={false}
                  onClose={this.onClose}
                  width={800}
                  visible={this.state.visible}
                  
                >
                  <div className='agenda-title'> Agenda  </div>
                  <BigCalendar
                        events={events.all}
                        defaultView='agenda'
                        views={["agenda"]}
                        showMultiDayTimes
                        localizer={localizer}
                        culture="pl"
                        messages={messages}
                        defaultDate={new Date(2018,11,1)}
                        length={events.max.diff(new Date(2018,11,1),'days')}
                        toolbar={false}
                        ref={el => (this.componentRef = el)}   />
                        <div
                            style={{
                              position: 'absolute',
                              bottom: 0,
                              width: '100%',
                              borderTop: '1px solid #e8e8e8',
                              padding: '10px 16px',
                              textAlign: 'right',
                              left: 0,
                              background: '#fff',
                              borderRadius: '0 0 4px 4px',
                            }}
                            className='agenda-footer'
                          >
                            <Button
                              style={{
                                marginRight: 8,
                              }}
                             onClick={()=>this.printAgenda()}>Drukuj
                            </Button>
                            <Button onClick={this.onClose} type="primary">
                              Zamknij
                            </Button>
                          </div>
                </Drawer>        
            </div>
        )
        }}
        </Query>
        )
    }
}
